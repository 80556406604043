import React, {useEffect, useState} from "react";
import style from "../Authorization/index.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../Base/components/Loader";
import TextInput from "../../../Base/components/Inputs/TextInput";
import Button from "../../../Base/components/Button";
import {dataDefaultPopupAuthorization} from "../../../Popup/store/constants";
import PopupData from "../../../Base/helpers/popup-data";
import {POPUP_DATA_ERROR} from "../../../Base/constants/popup-data-error";
import {connect} from "react-redux";
import {setPopup, setPopupAuthorization} from "../../../Popup/store/actions";
import {changePassRequest} from "../../store/actions";
import {POPUP_AUTHORIZATION_TYPE} from "../../../Popup/store/types";


const ChangePassword = (
    {
        setPopupAuthorization,
        setPopup,
        changePassRequest,
        isLoad,
        error,
        sendMail
    }) => {

    const [login, setLogin] = useState({
        value: '',
        isError: false,
        error: ''
    });

    useEffect(() => {
        setLogin({...login, isError: !!error, error: error})
    }, [error]);

    useEffect( () => {
        if (sendMail) {
            const dataPopup = PopupData(POPUP_DATA_ERROR.CHANGE_SEND_PASSWORD, ()=>{
                setPopupAuthorization(dataDefaultPopupAuthorization);
            });
            setPopup(dataPopup);
        }

    }, [sendMail])

    const sendChange = () => {
        changePassRequest(login.value);
    };

    return (

        <div className={style['container']}>
            <Loader flag={isLoad}>
                <div className={style['header']}>
                    <div className={style['title']}>Забыли пароль?</div>
                    <div
                        className={style['close']}
                        title={'Закрыть'}
                        onClick={() => {
                            setPopupAuthorization(dataDefaultPopupAuthorization);
                        }}
                    >
                        <CloseIcon/>
                    </div>
                </div>
                <div className={style['content']}>
                    <div className={style['element']}>
                        <TextInput
                            ico={'MailOutlineIcon'}
                            error={login.isError}
                            label={"Логин, e-mail"}
                            value={login.value}
                            onChange={event => {
                                if (isLoad) return;
                                setLogin({...login, value: event.target.value, isError: false, error: ''});
                            }}
                            helperText={login.error}
                        />
                    </div>
                </div>
                <div className={style['footer']}>
                    <Button
                        name={'Восстановить'}
                        active={login.value !== '' && !login.error}
                        isEnter={true}
                        onClick={() => {
                            sendChange();
                        }}
                    />
                    <div className={style['footer_text']}>
                        <div
                            className={style['footer_href']}
                            onClick={() => {
                                setPopupAuthorization({type: POPUP_AUTHORIZATION_TYPE.AUTHORIZATION, isOpen: true})
                            }}
                            title={'Личный кабинет'}
                        >Личный кабинет
                        </div>
                    </div>
                </div>
            </Loader>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        isLoad: state.auth.changePass.isLoad,
        error: state.auth.changePass.error,
        sendMail: state.auth.changePass.sendMail,
    }
};

const mapDispatchToProps = {
    setPopupAuthorization, setPopup, changePassRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);