import {AUTH, CHANGE_PASSWORD, LOGOUT, REGISTRATION} from "./constants";
import {getLocalStorage} from "../../Base/helpers/local-storage";
import {LOCAL_STORAGE_CODE} from "../../Base/constants/local-storage";
import {CHECK_HASH, IRegistration, NEW_PASS} from "./types";

export interface IAuthState {
    token: string,
    isAuth: boolean,
    error: string,
    isLoad: boolean,
    registration: IRegistration,
    logout: boolean,
    changePass: {
        isLoad: boolean,
        error: string,
        sendMail: boolean,
    }
    recoveryPass: {
        isLoad: boolean,
        error: string,
        checkHash: CHECK_HASH,
        userId: number,
        saveNewPass: NEW_PASS,
    }
}

const authLocalStorage = getLocalStorage(LOCAL_STORAGE_CODE.AUTH);

let isAuth = false;
let token = '';

if (authLocalStorage) {
    isAuth = authLocalStorage.isAuth;
    token = authLocalStorage.token;
}

const initialState: IAuthState = {
    isAuth: isAuth,
    token: token,
    error: '',
    isLoad: false,
    registration: {
        isLoad: false,
        error: ''
    },
    logout: false,
    changePass: {
        isLoad: false,
        error: '',
        sendMail: false,
    },
    recoveryPass: {
        isLoad: false,
        error: '',
        checkHash: CHECK_HASH.NOT_REQUEST,
        userId: 0,
        saveNewPass: NEW_PASS.NOT_REQUEST,
    }
}

export const authReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case AUTH.AUTH_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case AUTH.AUTH_ANSWER:
            const data = action.payload;
            return {
                ...store,
                isAuth: data.isAuth || false,
                token: data.token || '',
                error: data.error || '',
                isLoad: data.isLoad || false,
            };
        case AUTH.CLEAR_ERROR:
            return {
                ...store,
                error: '',
                isLoad: false,
            }

        case LOGOUT.REQUEST:
            return store;
        case LOGOUT.ANSWER:
            return {
                ...store,
                logout: action.payload,
            }

        case REGISTRATION.REQUEST:
            return {
                ...store,
                registration: {
                    ...store.registration,
                    isLoad: true,
                }
            }
        case REGISTRATION.ERROR:
            const error = action.payload;
            return {
                ...store,
                registration: {
                    ...store.registration,
                    isLoad: false,
                    error: error
                }
            }
        case REGISTRATION.CLEAR:
            return {
                ...store,
                registration: {
                    ...store.registration,
                    isLoad: false,
                    error: ''
                }
            }

        case CHANGE_PASSWORD.REQUEST:
            return {
                ...store,
                changePass: {
                    ...store.changePass,
                    isLoad: true
                },
            }
        case CHANGE_PASSWORD.ANSWER:
            return {
                ...store,
                changePass: {
                    ...store.changePass,
                    isLoad: false,
                    sendMail: true,
                },
            }
        case CHANGE_PASSWORD.ERROR:
            return {
                ...store,
                changePass: {
                    ...store.changePass,
                    isLoad: false,
                    error: action.payload
                },
            }
        case CHANGE_PASSWORD.CLEAR:
            return {
                ...store,
                changePass: {
                    ...store.changePass,
                    isLoad: false,
                    error: '',
                    sendMail: false
                },
            }

        case CHANGE_PASSWORD.CHECK_HASH_REQUEST:
            return {
                ...store,
                recoveryPass: {
                    ...store.recoveryPass,
                    isLoad: true,
                },
            }
        case CHANGE_PASSWORD.CHECK_HASH_ANSWER:
            const {checkHash, userId} = action.payload;
            return {
                ...store,
                recoveryPass: {
                    ...store.recoveryPass,
                    isLoad: false,
                    checkHash: checkHash,
                    userId: userId
                },
            }

        case CHANGE_PASSWORD.NEW_PASS_REQUEST:
            return {
                ...store,
                recoveryPass: {
                    ...store.recoveryPass,
                    isLoad: true,
                },
            }
        case CHANGE_PASSWORD.NEW_PASS_ANSWER:
            return {
                ...store,
                recoveryPass: {
                    ...store.recoveryPass,
                    isLoad: false,
                    saveNewPass: action.payload,
                },
            }
    }
    return store;
}
