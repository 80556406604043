import {IPayDataList, PAY_METHODS} from "./types";
import {PAY} from "./constants";

export interface IPayState {
    data: {
        list: IPayDataList[],
        error: boolean,
        isLoad: boolean
    }
    method: PAY_METHODS,
}

const initialState: IPayState = {
    data: {
        list: [],
        error: false,
        isLoad: true,
    },
    method: PAY_METHODS.YOO_KASSA,

}

export const payReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case PAY.GET_LIST_REQUEST:
            return {
                ...store,
                data: {
                    ...store.data
                }
            }
        case PAY.GET_LIST_ANSWER:
            return {
                ...store,
                data: {
                    ...store.data,
                    isLoad: false,
                    list: action.payload
                }
            }
        case PAY.GET_LIST_ERROR:
            return {
                ...store,
                data: {
                    ...store.data,
                    error: action.payload
                }
            }
    }
    return store;
}
