import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#dcdcdc'
    },

})(AttachFileIcon);

const AttachFileIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default AttachFileIconStyle;