export enum POPUP_TYPE {
    ALERT = 'alert'
}

export enum POPUP_AUTHORIZATION_TYPE {
    NONE = '',
    AUTHORIZATION = 'authorization',
    REGISTRATION = 'registration',
    FORGET_PASSWORD = 'forget_password',
}

export interface ISetPopup {
    title?: string,
    description?: string,
    type?: POPUP_TYPE,
    isOpen?: boolean,
    html?: boolean,
    onClick?: ()=> void
}

export interface ISetPopupAuthorization {
    type?: POPUP_AUTHORIZATION_TYPE,
    isOpen?: boolean,
    url?: string,
    name?: {
        title: string,
        button: string
    }
}