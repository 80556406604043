import React from 'react';
import GroupIcon from '@material-ui/icons/Group';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(GroupIcon);

const GroupIconStyle = () => {
    return (
        <Style />
    );
};


export default GroupIconStyle;