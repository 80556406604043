//@ts-nocheck
import {call, put, takeLatest} from "redux-saga/effects";
import {PAGES} from "./constants";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {getDataPageAnswer} from "./actions";

function* getDataPageRequestSaga({payload}: any) {

    const request = yield call(getResource, '/getDataPage/', METHOD_REQUEST.POST, {}, {data: {url: payload}});

    if (request.success) {
        yield put(getDataPageAnswer(request.success.data))
    }
}

export default function* watchPages() {
    yield takeLatest(PAGES.GET_DATA_REQUEST, getDataPageRequestSaga);
}
