import {USER, defaultDataUser} from "./constants";
import {IUserData} from "./types";

export interface IUserState {
    data: IUserData,
    error: number,
    isLoad: boolean,
}

const initialState: IUserState = {
    data: defaultDataUser,
    error: 0,
    isLoad: true,
}

export const userReducer = ( store = initialState, action: {type:string, payload?: any}) => {
    switch (action.type) {
        case USER.GET_DATA_REQUEST:
            return store;
        case USER.GET_DATA_ANSWER:
            const data = action.payload;
            return {
                ...store,
                data: data,
                isLoad: false,
            };
        case USER.GET_DATA_ERROR:
            const error: number = action.payload;
            return {
                ...store,
                error: error
            };
        case USER.UPDATE_DATA_REQUEST:
            return {
                ...store,
                isLoad: true
            };
    }
    return store;
}
