import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import style from './index.module.scss';
import {InputAdornment} from '@material-ui/core';
import Loader from "../../../Base/components/Loader";
import {setPopupAuthorization} from "../../../Popup/store/actions";
import {CloseIcon, VisibilityIcon, VisibilityOffIcon} from "../../../MU/Icons";
import TextInput from "../../../Base/components/Inputs/TextInput";
import Button from "../../../Base/components/Button";
import {authRequest} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {dataDefaultPopupAuthorization} from "../../../Popup/store/constants";
import {POPUP_AUTHORIZATION_TYPE} from "../../../Popup/store/types";

const Authorization = (
    {
        setPopupAuthorization,
        popupAuthorization,
        authRequest,
        auth
    }) => {

    const history = useHistory();

    const [login, setLogin] = useState({
        value: '',
        isError: false,
        error: ''
    });
    const [password, setPassword] = useState({
        value: '',
        isError: false,
        error: '',
        show: false
    });

    useEffect(() => {
        if (auth.error) {
            setLogin({...login, isError: true, error: auth.error});
            setPassword({...password, isError: true, error: auth.error});
            return;
        }

        if (auth.isAuth && auth.token) {
            history.push(popupAuthorization.url);
        }
    }, [auth]);

    const newAuthorization = () => {
        const data = {
            login: login.value,
            password: password.value
        }
        authRequest(data);
    };

    return (

        <div className={style['container']}>
            <Loader flag={auth.isLoad}>
                <div className={style['header']}>
                    <div className={style['title']}>Личный кабинет</div>
                    <div className={style['close']}
                         title={'Закрыть'}
                         onClick={() => {
                             setPopupAuthorization(dataDefaultPopupAuthorization);
                         }}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={style['content']}>
                    <div className={style['element']}>
                        <TextInput
                            ico={'PersonIcon'}
                            error={login.isError}
                            label={"Логин, e-mail"}
                            value={login.value}
                            onChange={event => {
                                if (auth.isLoad) return;
                                setLogin({...login, value: event.target.value, isError: false, error: ''});
                                setPassword({...password, isError: false, error: ''});
                            }}
                            helperText={login.error}
                        />
                    </div>
                    <div className={style['element']}>
                        <TextInput
                            ico={'LockIcon'}
                            type={password.show ? 'text' : 'password'}
                            error={password.isError}
                            label={"Пароль"}
                            value={password.value}
                            onChange={event => {
                                if (auth.isLoad) return;
                                setPassword({...password, value: event.target.value, isError: false, error: ''});
                                setLogin({...login, isError: false, error: ''});
                            }}
                            helperText={password.error}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"
                                                              title={password.show ? 'Скрыть пароль' : 'Показать пароль'}>
                                    {
                                        password.value !== ''
                                            ? password.show
                                            ? <VisibilityOffIcon
                                                onClick={() => {
                                                    setPassword({...password, show: false})
                                                }}
                                            />
                                            : <VisibilityIcon
                                                onClick={() => {
                                                    setPassword({...password, show: true})
                                                }}
                                            />
                                            : ''
                                    }
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div>
                <div className={style['footer']}>
                    <Button
                        name={'Войти в личный кабинет'}
                        active={login.value !== '' && !login.error && password.value !== '' && !password.error}
                        isEnter={true}
                        onClick={() => {
                            newAuthorization();
                        }}
                    />
                    <div className={style['footer_text']}>
                        <div
                            className={style['footer_href_auth']}
                            onClick={() => {
                                setPopupAuthorization({type: POPUP_AUTHORIZATION_TYPE.REGISTRATION, isOpen: true})
                            }}
                            title={'Нет личного кабинета?'}
                        >Нет личного кабинета?
                        </div>
                    </div>
                    <div
                        className={style['footer_href_forget']}
                        title={'Забыли пароль?'}
                        onClick={() => {
                            setPopupAuthorization({type: POPUP_AUTHORIZATION_TYPE.FORGET_PASSWORD, isOpen: true})
                        }}
                    >Забыли пароль?
                    </div>
                </div>
            </Loader>
        </div>
    );

};


const mapStateToProps = (state) => {
    return {
        popupAuthorization: state.popup.popupAuthorization,
        auth: state.auth
    }
};

const mapDispatchToProps = {
    setPopupAuthorization, authRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);