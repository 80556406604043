import React, { useEffect, useState } from 'react';
import styles from "./App.module.scss";
import Header from "../Base/components/Header";
import Footer from "../Base/components/Footer";
import Routes from "../Base/components/Routes";
import {connect} from 'react-redux';
import {checkToken} from '../Auth/store/actions';
import {BaseState} from "../Base/rootReducer";
import { useLocation } from 'react-router-dom';
import cx from 'classnames';


interface IApp {
    checkToken: () => void,
    token: string,
}

function App(
    {
        checkToken,
        token
    }: IApp
) {
    const [minWidth, setMinWidth] = useState<number>(320);
    const location = useLocation();

    useEffect(() => {
        checkToken()
    }, [checkToken, token])

    useEffect(() => {
        setMinWidth(location.pathname === '/' ? 320 : 500);
    }, [location])

    return (
        <div className={styles.root} style={{minWidth: minWidth}}>
            <div className={styles.container} style={{minWidth: location.pathname === '/' ? 'auto' : minWidth-20}}>
                <div className={cx(styles.header, {
                    [styles.header_fon]: location.pathname !== '/'
                })}>
                    <Header/>
                </div>
                <div className={styles.content}>
                    <Routes />
                </div>
                <div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: BaseState) => {
    return {
        token: state.auth.token
    }
}

const mapDispatchToProps = {
    checkToken
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(App);
