import React from "react";
import {FormControlLabel, Checkbox} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
const CheckboxMU = ({...props}) => {
    const {label} = props;
    const CheckboxStyle = withStyles(()=>{
        const obj = {
            root: {
                color: '#39b531',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }
        };

        return obj;
    })(props => <Checkbox color="default" {...props} />);

    return (
        <FormControlLabel
            control={
                <CheckboxStyle {...props} />
            }
            label={label}
        />

    );

};

export default CheckboxMU;