import React from 'react';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color:'#39b531'
    },

})(HowToRegIcon);

const HowToRegIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default HowToRegIconStyle;