import {RATES_CODE} from "../../Test/constants";
import {PACKAGE} from "../../Package/constants";
import {TESTS} from "../../Test/store/type";

export interface IPayDataList {
    id: number,
    user: number,
    name: string,
    date_create: number,
    date_finish: number,
    status: number,
    price: number,
    code: RATES_CODE
    package_result: number,
    marathon_id: number,
    marathon_date_start: number,
    isRun: boolean,
    hash: string,
    package: PACKAGE
}

export interface ISendDataNewPay {
    name: string,
    id: number,
    userId: number | null,
    mail: string,
    price: number,
    code: RATES_CODE
}

export enum PAY_METHODS {
    PAY_BOX = 'paybox',
    PAY_MASTER = 'paymaster',
    YOO_KASSA = 'yookassa'
}

export interface ISendPayYooKassa {
    amount: {
        value: number,
        currency: 'RUB',
    },
    description: string,
    confirmation: {
        type: string,
    },
    metadata: {
        url: string,
    },
    merchant_customer_id?: string,
    capture: boolean,

}

export interface IDataTestSendPay {
    code: TESTS,
    name: string,
    time_limit: number | undefined
}

