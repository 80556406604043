export enum DOWNLOAD_CONSTANTS {
    TEST_DATA_REQUEST = 'DOWNLOAD/TEST_DATA_REQUEST',
    TEST_DATA_ANSWER = 'DOWNLOAD/TEST_DATA_ANSWER',
    TEST_DATA_ERROR = 'DOWNLOAD/TEST_DATA_ERROR',

    PACKAGE_DATA_REQUEST = 'DOWNLOAD/PACKAGE_DATA_REQUEST',
    PACKAGE_DATA_ANSWER = 'DOWNLOAD/PACKAGE_DATA_ANSWER',
    PACKAGE_DATA_ERROR = 'DOWNLOAD/PACKAGE_DATA_ERROR',

    CURATOR_PACKAGE_DATA_REQUEST = 'DOWNLOAD/CURATOR_PACKAGE_DATA_REQUEST',
    CURATOR_PACKAGE_DATA_ANSWER = 'DOWNLOAD/CURATOR_PACKAGE_DATA_ANSWER',
    CURATOR_PACKAGE_DATA_ERROR = 'DOWNLOAD/CURATOR_PACKAGE_DATA_ERROR',

}