import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root:{
        color: '#39b531',
        cursor: 'pointer'
    }
})(VisibilityIcon);

const VisibilityIconStyle = ({...props}) => {
    return (
        <Style {...props}/>
    );
};


export default VisibilityIconStyle;