//@ts-nocheck
import {call, select, takeLatest, put} from "redux-saga/effects";
import {USER} from "./constants";
import {BaseState} from "../../Base/rootReducer";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {getDataUserAnswer, getDataUserError, getDataUserRequest} from "./actions";
import {IUserData} from "./types";


function* getDataRequestSaga() {
    const token = yield select((state: BaseState) => state.auth.token);

    const request = yield call(getResource, '/profile/', METHOD_REQUEST.GET, {"Authorization": "Token " + token}, {});
    if (request.success) {
        const data: IUserData = request.success.data;
        data.id = Number(data.id);
        data.curator = Number(data.curator);
        data.admin = Number(data.admin);
        data.year = Number(data.year);

        yield put(getDataUserAnswer(request.success.data))
    }

    if (request.error) {
        yield put(getDataUserError(request.error.code))
    }

}

function* updateDataUserRequestSaga({payload}: { payload: any }) {
    const token = yield select((state: BaseState) => state.auth.token);

    const request = yield call(getResource, '/saveDataUser/', METHOD_REQUEST.POST, {"Authorization": "Token " + token}, {data: payload});
    if (request.success) {
        yield put(getDataUserRequest())
    }

    if (request.error) {
        // Обработка ошибки
    }
}

export default function* watchUser() {
    yield takeLatest(USER.GET_DATA_REQUEST, getDataRequestSaga);
    // @ts-ignore
    yield takeLatest(USER.UPDATE_DATA_REQUEST, updateDataUserRequestSaga);
}
