import {ISetPopup, ISetPopupAuthorization} from "./types";
import {dataDefaultPopupAuthorization, POPUP, dataDefaultPopup} from "./constants";

export interface IStatePopup {
    popup: ISetPopup, // Попапы по системе - alert, prompt, confirm
    popupAuthorization: ISetPopupAuthorization, // Попапы Авторизации, Регистрации, Смены пароля
}

const initialState: IStatePopup = {
    popup: dataDefaultPopup,
    popupAuthorization: dataDefaultPopupAuthorization
}

export const popupReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case POPUP.SET_POPUP:
            const {title, description, type:TypePopup, isOpen:isOpenPopup, html, onClick} = action.payload;
            return  {
                ...store,
                popup: {title, description, type:TypePopup, isOpen:isOpenPopup, html, onClick}
            };
        case POPUP.SET_POPUP_AUTHORIZATION:
            const {type, isOpen, url, name} = action.payload;
            return  {
                ...store,
                popupAuthorization: {type, isOpen, url, name}
            };
    }
    return store;
}
