import {ISetPopupAuthorization, POPUP_AUTHORIZATION_TYPE} from "../../../../Popup/store/types";
import React from "react";

export enum BUTTON_AUTHORIZATION_TYPE {
    BUTTON = 'button',
    ICO = 'ico',
    TEXT = 'text'
}

export interface IButtonType {
    width: number,
    name: string,
    flag: POPUP_AUTHORIZATION_TYPE,
    setPopupAuthorization: (data: ISetPopupAuthorization) => void,
    url: string,
    marathon: boolean,
    authorization: boolean,
}

export interface IIcoType {
    name: string,
    flag: POPUP_AUTHORIZATION_TYPE,
    setPopupAuthorization: (data: ISetPopupAuthorization) => void,
    url: string,
    authorization: boolean,
    logoutRequest: () => void,
    logoutAnswer: (flag: boolean) => void,
    logout: boolean
}


export interface IDefaultType {
    name: string,
    textStyle: string,
    flag: POPUP_AUTHORIZATION_TYPE,
    setPopupAuthorization: (data: ISetPopupAuthorization) => void,
    url: string,
    authorization: boolean
}

