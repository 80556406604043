import {PAGES} from "./constants";
import {IMetaPage} from "../../Base/helpers/get-data-page";

export const getDataPageRequest = (url: string) => {
    return {
        type: PAGES.GET_DATA_REQUEST,
        payload: url
    }
}

export const getDataPageAnswer = (data: IMetaPage) => {
    return {
        type: PAGES.GET_DATA_ANSWER,
        payload: data
    }
}