//@ts-nocheck
import {call, put, takeLatest} from "redux-saga/effects";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {PACKAGE_CONSTANTS} from "./constants";
import {
    getDataPackageAnswer,
    getDataPackageError,
    getDataPackageRequest,
    getUpdateDataPackage,
    sendMessageMailStartPackage,
    startPackageAnswer
} from "./actions";
import {sendMessageTelegram} from "../../Telegram/store/actions";
import {MESSAGES_TELEGRAM} from "../../Telegram/store/constants";
import mainRoutes from "../../Base/constants/routes";
import {PACKAGE} from "../constants";
import {getGender} from "../../User/helpers/getGender";

const routes = mainRoutes();


function* startPackageSaga({payload}: any) {
    try {
        const request = yield call(
            getResource,
            '/startPackage/',
            METHOD_REQUEST.POST,
            {},
            {data: payload}
        );

        if (request.success) {
            const {data} = request.success;
            yield put(getDataPackageAnswer(data))
            // yield put(startPackageAnswer(data.hash))

            // Отправляем уведомление в телеграмм бот
            const url = routes.package.url + '/' + data.hash

            const message = MESSAGES_TELEGRAM.START_PACKAGE + '\n' +
                'Номер тестирования - <b>' + data.id + '</b>.\n' +
                'Пол - <b>' + getGender(payload.gender) + '</b>.\n' +
                'Возраст - <b>' + payload.age + '</b>.\n' +
                '<a href="' + window.location.origin + url + '">Ссылка на тестирование </a>';

            yield put(sendMessageTelegram(message));

        }

        // if (request.error) {
        //     error = request.error.error_message;
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataPackageSaga({payload}: any) {
    try {
        const url = payload.isCurator ? '/getDataCuratorPackage/' : '/getDataPackage/';

        const request = yield call(
            getResource,
            url,
            METHOD_REQUEST.POST,
            {},
            {data: {hash: payload.hash}}
        );

        if (request.success) {
            const {data} = request.success;
            yield put(getDataPackageAnswer(data))
        }

        if (request.error) {
            const error = request.error.code;
            yield put(getDataPackageError(error));
            console.warn(error);
        }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* startTestPackageSaga({payload}: any) {
    try {
        const url = payload.isCurator ? '/startCuratorTest/' : '/startTest/'

        const request = yield call(
            getResource,
            url,
            METHOD_REQUEST.POST,
            {},
            {data: payload}
        );

        yield put(getUpdateDataPackage());

        // if (request.success) {
        //     const {data} = request.success;
        //     yield put(getDataPackageAnswer(data))
        // }
        //
        // if (request.error) {
        //     const error = request.error.code;
        //     yield put(getDataPackageError(error));
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* finishPackageSaga({payload}: any) {
    try {
        const url = payload.isCurator ? '/finishCuratorPackage/' : '/finishPackage/';

        const request = yield call(
            getResource,
            url,
            METHOD_REQUEST.POST,
            {},
            {data: {id: payload.packageId}}
        );

        if (request.success && request.success.data) {
            const {data} = request.success;

            if (!payload.isCurator) {
                // Отправляем уведомление в телеграмм бот
                const url = routes.package.url + '/' + data.hash;
                const message = MESSAGES_TELEGRAM.FINISH_PACKAGE + '\n' +
                    'Номер тестирования - <b>' + payload.packageId + '</b>.\n' +
                    '<a href="' + window.location.origin + url + '">Ссылка на тестирование </a>';

                yield put(sendMessageTelegram(message));
            }

            yield put(getDataPackageRequest({hash: data.hash, isCurator: payload.isCurator}));
        }
        //
        // if (request.error) {
        //     const error = request.error.code;
        //     yield put(getDataPackageError(error));
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* finishInterpretationPackageSaga({payload}: any) {
    try {
        const request = yield call(
            getResource,
            '/finishStatusPackage/',
            METHOD_REQUEST.POST,
            {},
            {data: payload}
        );

        yield put(getDataPackageRequest({hash: payload.hash, isCurator: false}));

        //
        // if (request.error) {
        //     const error = request.error.code;
        //     yield put(getDataPackageError(error));
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* sendMessageMailStartPackageSaga({payload}: any) {
    try {
        yield call(
            getResource,
            '/sendMessageMailStartPackage/',
            METHOD_REQUEST.POST,
            {},
            {data: payload}
        );


    } catch (error) {

    }
}

export default function* watchPackage() {
    yield takeLatest(PACKAGE_CONSTANTS.START_REQUEST, startPackageSaga);
    yield takeLatest(PACKAGE_CONSTANTS.GET_DATA_REQUEST, getDataPackageSaga);
    yield takeLatest(PACKAGE_CONSTANTS.START_TEST, startTestPackageSaga);
    yield takeLatest(PACKAGE_CONSTANTS.FINISH_PACKAGE, finishPackageSaga);
    yield takeLatest(PACKAGE_CONSTANTS.FINISH_INTERPRETATION_PACKAGE, finishInterpretationPackageSaga);
    yield takeLatest(PACKAGE_CONSTANTS.SEND_MESSAGE_MAIL_START_PACKAGE, sendMessageMailStartPackageSaga);
}