import React from 'react';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(AttachMoneyIcon);

const AttachMoneyIconStyle = ( {children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default AttachMoneyIconStyle;