import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    }
})(MailOutlineIcon);

const MailOutlineIconStyle = ({children, ...props}) => {
    return (
        <Style {...props}>
            {children}
        </Style>
    );
};


export default MailOutlineIconStyle;