import React from "react";
import {connect} from "react-redux";
import Registration from "./Registration";
import Authorization from "./Authorization";
import ChangePassword from "./ChangePassword";
import {Popup} from "../../Popup/components";
import {setPopupAuthorization} from "../../Popup/store/actions";
import {POPUP_AUTHORIZATION_TYPE} from "../../Popup/store/types";
import {dataDefaultPopupAuthorization} from "../../Popup/store/constants";

const PopupAuthorization = (
    {
        type,
        isOpen,
        setPopupAuthorization,
    }) => {

    let block;

    switch (type) {
        case POPUP_AUTHORIZATION_TYPE.AUTHORIZATION:
            block = <Authorization/>;
            break;
        case POPUP_AUTHORIZATION_TYPE.REGISTRATION:
            block = <Registration/>;
            break;
        case POPUP_AUTHORIZATION_TYPE.FORGET_PASSWORD:
            block = <ChangePassword/>;
            break;
        default:
            block = null;
            break;
    }

    return (
        <Popup open={isOpen} onClose={() => {
            setPopupAuthorization(dataDefaultPopupAuthorization);
        }}>
            {block}
        </Popup>
    );
};

const mapStateToProps = (state) => {

    const {type, isOpen} = state.popup.popupAuthorization;
    return {type, isOpen};
};

const mapDispatchToProps = {
    setPopupAuthorization
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupAuthorization);