import {PACKAGE_CONSTANTS} from "./constants";
import {IDataRunPackage} from "./type";
import { STATUS_PACKAGE } from "../constants";

export interface IStatePackage {
    isLoad: boolean,
    hash: string,
    data: IDataRunPackage,
    error: number,
    update: boolean,
}


const initialState: IStatePackage = {
    hash: '',
    isLoad: false,
    // @ts-ignore
    data: {
        tests: []
    },
    error: 0,
    update: false,
}

export const packageReducer = (store = initialState, action: { type: PACKAGE_CONSTANTS, payload?: any }) => {
    switch (action.type) {
        case PACKAGE_CONSTANTS.START_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case PACKAGE_CONSTANTS.START_ANSWER:
            return {
                ...store,
                hash: action.payload,
                isLoad: false,
                data : {...store.data, status: STATUS_PACKAGE.RUN}
            };
        case PACKAGE_CONSTANTS.START_ERROR:
            return {
                ...store,
                isLoad: false
            };
        case PACKAGE_CONSTANTS.CLEAR_HASH:
            return {
                ...store,
                hash: '',
            };
        case PACKAGE_CONSTANTS.GET_DATA_REQUEST:
            return {
                ...store,
                isLoad: true,
            };
        case PACKAGE_CONSTANTS.GET_DATA_ANSWER:
            return {
                ...store,
                data: action.payload,
                isLoad: false,
            };
        case PACKAGE_CONSTANTS.GET_DATA_ERROR:
            return {
                ...store,
                error: action.payload,
                isLoad: false,
            };
        case PACKAGE_CONSTANTS.CLEAR_ERROR:
            return {
                ...store,
                error: 0,
            };
        case PACKAGE_CONSTANTS.START_TEST:
            return {
                ...store,
                isLoad: true,
            };
        case PACKAGE_CONSTANTS.FINISH_TEST:
            return {
                ...store,
                update: !store.update
            };
        case PACKAGE_CONSTANTS.FINISH_PACKAGE:
            return {
                ...store,
                update: !store.update
            };
        case PACKAGE_CONSTANTS.FINISH_INTERPRETATION_PACKAGE:
            return {
                ...store,
                update: !store.update
            };
        case PACKAGE_CONSTANTS.GET_UPDATE_DATA:
            return {
                ...store,
                update: !store.update
            };
        case PACKAGE_CONSTANTS.SEND_MESSAGE_MAIL_START_PACKAGE:
            return {
                ...store
            }
    }
    return store;
}
