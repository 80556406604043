import {LOCAL_STORAGE_CODE} from "../constants/local-storage";


export const getLocalStorage = (code: LOCAL_STORAGE_CODE): any => {
    const json = localStorage.getItem(code);
    if (json) return JSON.parse(json);
    return undefined;
}

export const setLocalStorage = (code: LOCAL_STORAGE_CODE, value: any): void => {
    localStorage.setItem(code, JSON.stringify(value));
}

export const clearLocalStorage = (): void => {
    localStorage.clear();
}
