import React from "react";
import style from "./index.module.scss";
import mainRoutes from "../../constants/routes";
import {Link} from "react-router-dom";
import {PayMasterMainUrl, yooKassaMainUrl} from "../../../Pay/constants";
import {useSelector} from "react-redux";
import {PAY_METHODS} from "../../../Pay/store/types";


const routes = mainRoutes();

const Footer = () => {

    const payMetod = useSelector((state) => state.pay.method);

    const getDataPay = () => {

        switch (payMetod) {
            case PAY_METHODS.PAY_MASTER:
                return <a href={PayMasterMainUrl} target={'_blank'} className={style.paymaster}
                          title={'Сервис приёма платежей для бизнеса — PayMaster'}/>
            case PAY_METHODS.YOO_KASSA:
                return <a href={yooKassaMainUrl} target={'_blank'} className={style.yookassa}
                          title={'ЮKassa — сервис, который позволяет включить приём платежей на сайте и получать деньги на расчётный счёт компании.'}/>
        }
    }


    return (
        <noindex className={style["footer_container"]}>
                <div className={style["position"]}>
                    <div className={style["footer_fon"]}/>
                    <div className={style["footer_fon_1"]}/>
                </div>
                <div className={style["footer"]}>
                    <div className={style["footer_margin"]}>
                        <div className={style["footer_header"]}>
                            <div className={style["footer_main"]}>
                                <div className={style["footer_title"]}>prof assistant</div>
                                <div className={style["footer_description"]}>
                                    Сервис профессионального психологического тестирования
                                </div>
                                <div className={style.payment}/>
                            </div>
                            <div className={style["footer_information"]}>
                                <div className={style["footer_title"]}>информация</div>
                                {/*<div className={style["footer_description_click"]}>*/}
                                {/*    <ButtonAuthorization*/}
                                {/*        type={BUTTON_AUTHORIZATION_TYPE.TEXT}*/}
                                {/*        name={routes.account.title}*/}
                                {/*        flag={POPUP_AUTHORIZATION_TYPE.AUTHORIZATION}*/}
                                {/*        url={routes.account.url}*/}
                                {/*        width={0}*/}
                                {/*        style={'footer'}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className={style["footer_description_click"]}>
                                    <Link
                                        to={routes.contract_offer.url}
                                        className={style["footer_a_click"]}
                                        title={routes.contract_offer.title}>{routes.contract_offer.title}</Link>
                                </div>
                                <div className={style["footer_description_click"]}>
                                    <Link
                                        to={routes.politika_konfidencialnosti.url}
                                        className={style["footer_a_click"]}
                                        title={routes.politika_konfidencialnosti.title}>{routes.politika_konfidencialnosti.title}</Link>
                                </div>
                                <div className={style["footer_description_click"]}>
                                    <Link to={routes.sitemap.url}
                                          className={style["footer_a_click"]}
                                          title={routes.sitemap.title}>{routes.sitemap.title}</Link>
                                </div>
                            </div>
                            <div className={style["footer_clients"]}>
                                <div className={style["footer_title"]}>О нас</div>
                                <div className={style["footer_description_click"]}>
                                    <div>Скобелев Алексей Сергеевич</div>
                                    <div>ИНН 380122017498</div>
                                </div>

                                <div className={style["footer_description_click"]}>
                                    {/*<div className={style.phone}>89500807955</div>*/}
                                    <a href={'mailto:info@prof-assistant.ru'}
                                       className={style.mail}>info@prof-assistant.ru</a>
                                </div>
                                {/*<div className={style["footer_description_click"]}>*/}
                                {/*    <a href={INSTAGRAM_URL} target={'_blank'} className={style.instagram}>Мы в instagram</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={style["footer_footer"]}>
                            <div className={style["footer_border"]}/>
                            <div className={style["footer_copyright"]}>© 2018-{new Date().getFullYear()} Prof Assistant
                            </div>
                            {
                                getDataPay()
                            }
                        </div>
                    </div>

                </div>
        </noindex>
    );
};

export default Footer;
