/**
 * Created by AL on 28.08.2018.
 */

import React from 'react'
import style from './index.module.scss'
import {BUTTON_FORM, BUTTON_TYPE} from "./type";


export interface IButton {
    form: BUTTON_FORM,
    active: boolean,
    type: BUTTON_TYPE,
    name: string,
    isEnter: boolean,
    onClick: (event: MouseEvent | KeyboardEvent) => void,
}


function Button(props: IButton) {

    window.document.onkeydown = function (event) {
        const {active, isEnter} = props;
        const keyCode = event.which || event.keyCode;
        if (keyCode === 13 && active && isEnter) props.onClick(event);
    };

    if (props.active && props.type) {


        return <div className={style[`button_${props.type}_${props.form}`]}
                    // @ts-ignore
                    onClick={(event) => props.onClick(event)}
                    title={props.name}
        >{props.name}</div>
    } else {
        return <div className={style[`button_grey_${props.form}`]} title={props.name}>{props.name}</div>
    }
}

Button.defaultProps = {
    form: 'oval', //square
    active: true,
    type: 'orange',
    isEnter: false,
    onClick: () => {
    },
};

export default Button;
