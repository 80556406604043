import { all, fork } from 'redux-saga/effects';
import watchAuth from '../Auth/store/saga';
import watchUser from '../User/store/saga';
import watchPay from '../Pay/store/saga';
import watchTest from '../Test/store/saga';
import watchPackage from "../Package/store/saga";
import watchDownload from "../Download/store/saga";
import watchTelegram from "../Telegram/store/saga";
import watchProfessions from "../Professions/store/saga";
import watchCurators from "../Curators/store/saga";
import watchPages from "../Pages/store/saga";

export default function *watchAll() {
    yield all([
        fork(watchAuth),
        fork(watchUser),
        fork(watchTest),
        fork(watchPay),
        fork(watchPackage),
        fork(watchDownload),
        fork(watchTelegram),
        fork(watchProfessions),
        fork(watchCurators),
        fork(watchPages)
    ]);
}
