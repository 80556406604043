import {IDataAuthAnswer, IDataAuthRequest, CHECK_HASH, NEW_PASS} from "./types"
import {AUTH, CHANGE_PASSWORD, LOGOUT, REGISTRATION} from "./constants"

export const authRequest = (request: IDataAuthRequest) => {
    return {
        type: AUTH.AUTH_REQUEST,
        payload: request
    }
}

export const authAnswer = (answer: IDataAuthAnswer) => {
    return {
        type: AUTH.AUTH_ANSWER,
        payload: answer
    }
}

export const authClearError = () => {
    return {
        type: AUTH.CLEAR_ERROR
    }
}

export const checkToken = () => {
    return {
        type: AUTH.CHECK_TOKEN
    }
}

export const logoutRequest = () => {
    return {
        type: LOGOUT.REQUEST
    }
}

export const logoutAnswer = (flag: boolean) => {
    return {
        type: LOGOUT.ANSWER,
        payload: flag
    }
}

export const registrationRequest = (login: string) => {
    return {
        type: REGISTRATION.REQUEST,
        payload: login
    }
}

export const registrationError = (error: string) => {
    return {
        type: REGISTRATION.ERROR,
        payload: error
    }
}

export const registrationClear = () => {
    return {
        type: REGISTRATION.CLEAR
    }
}

export const changePassRequest = (login: string) => {
    return {
        type: CHANGE_PASSWORD.REQUEST,
        payload: login
    }
}

export const changePassError = (error: string) => {
    return {
        type: CHANGE_PASSWORD.ERROR,
        payload: error
    }
}

export const changePassClear = () => {
    return {
        type: CHANGE_PASSWORD.CLEAR
    }
}

export const changePassAnswer = () => {
    return {
        type: CHANGE_PASSWORD.ANSWER
    }
}

export const checkHashRequest = (hash: string) => {
    return {
        type: CHANGE_PASSWORD.CHECK_HASH_REQUEST,
        payload: hash
    }
}

export const checkHashAnswer = (answer: {userId: number, checkHash: CHECK_HASH}) => {
    return {
        type: CHANGE_PASSWORD.CHECK_HASH_ANSWER,
        payload: answer
    }
}

export const newPassRequest = (data: {userId: number, pass: string}) => {
    return {
        type: CHANGE_PASSWORD.NEW_PASS_REQUEST,
        payload: data
    }
}

export const newPassAnswer = (answer: NEW_PASS) => {
    return {
        type: CHANGE_PASSWORD.NEW_PASS_ANSWER,
        payload: answer
    }
}

export const newPassClear = () => {
    return {
        type: CHANGE_PASSWORD.NEW_PASS_CLEAR,
    }
}