import {combineReducers, Reducer} from "redux";
import {userReducer, IUserState} from "../User/store/store";
import {authReducer, IAuthState} from "../Auth/store/store";
import {IStatePopup, popupReducer} from "../Popup/store/store";
import {IStateTest, testReducer} from "../Test/store/store";
import {IPayState, payReducer} from "../Pay/store/store";
import {IStatePackage, packageReducer} from "../Package/store/store";
import {IStateDownload, downloadReducer} from "../Download/store/store";
import {ITelegramState, telegramReducer} from "../Telegram/store/store";
import {IStateProfessions, professionsReducer} from "../Professions/store/store";
import {curatorsReducer, IStateCurators} from "../Curators/store/store";
import {IPageState, pageReducer} from "../Pages/store/store";


export interface BaseState {
    user: IUserState,
    auth: IAuthState,
    popup: IStatePopup,
    test: IStateTest,
    pay: IPayState,
    package: IStatePackage,
    download: IStateDownload,
    telegram: ITelegramState,
    professions: IStateProfessions,
    curators: IStateCurators,
    page: IPageState,
}

// @ts-ignore
export const rootReducer: Reducer<BaseState, any> = combineReducers({
    user: userReducer,
    auth: authReducer,
    popup: popupReducer,
    test: testReducer,
    pay: payReducer,
    package: packageReducer,
    download: downloadReducer,
    telegram: telegramReducer,
    professions: professionsReducer,
    curators: curatorsReducer,
    page: pageReducer,
})

// export default typeof rootReducer;
