import {POPUP_DATA_ERROR, popupDataError} from "../constants/popup-data-error";
import {POPUP_TYPE} from "../../Popup/store/types";

const PopupData = (error: POPUP_DATA_ERROR, onClick = ()=>{}) => {

    let popupData = popupDataError.get(error);
    if (!popupData) popupData = popupDataError.get(POPUP_DATA_ERROR.DEFAULT);

    return  {
        title: popupData ? popupData.title : '',
        description: popupData ? popupData.description : '',
        type:POPUP_TYPE.ALERT,
        isOpen: true,
        html: false,
        onClick: onClick
    };
};

export default PopupData;
