import {call, takeLatest} from "redux-saga/effects";
import {TELEGRAM} from "./constants";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";

function* sendMessageTelegramSaga ({payload}: any) {
    yield call(getResource, '/sendMessageTelegram/', METHOD_REQUEST.POST,{}, {data: payload});
}

export default function* watchTelegram() {
    yield takeLatest(TELEGRAM.SEND_MESSAGE, sendMessageTelegramSaga);
}
