import {IStartDataPackageRequest} from "./type";
import {PACKAGE_CONSTANTS} from "./constants";
import {TESTS_CONSTANTS} from "../../Test/store/constants";

export const startPackageRequest = (data: IStartDataPackageRequest) => {
    return {
        type: PACKAGE_CONSTANTS.START_REQUEST,
        payload: data
    }
};

export const startPackageAnswer = (hash: string) => {
    return {
        type: PACKAGE_CONSTANTS.START_ANSWER,
        payload: hash
    }
};

export const startPackageError = () => {
    return {
        type: PACKAGE_CONSTANTS.START_ERROR,
        // payload: data
    }
};

export const clearPackageHash = () => {
    return {
        type: PACKAGE_CONSTANTS.CLEAR_HASH,
    }
};


export const getDataPackageRequest = (data: {hash: string, isCurator: boolean}) => {
    return {
        type: PACKAGE_CONSTANTS.GET_DATA_REQUEST,
        payload: data
    }
};

export const getDataPackageAnswer = (data: any) => {
    return {
        type: PACKAGE_CONSTANTS.GET_DATA_ANSWER,
        payload: data
    }
};

export const getDataPackageError = (error: number) => {
    return {
        type: PACKAGE_CONSTANTS.GET_DATA_ERROR,
        payload: error
    }
};

export const clearErrorPackage = () => {
    return {
        type: PACKAGE_CONSTANTS.CLEAR_ERROR,
    }
};

export const startTestPackage = (data: {testId: number, packageId: number, isCurator: boolean}) => {
    return {
        type: PACKAGE_CONSTANTS.START_TEST,
        payload: data
    }
};

export const finishTestPackage = () => {
    return {
        type: PACKAGE_CONSTANTS.FINISH_TEST,
    }
};

export const finishPackage = (data: {packageId: number, isCurator: boolean}) => {
    return {
        type: PACKAGE_CONSTANTS.FINISH_PACKAGE,
        payload: data
    }
};

export const finishInterpretationPackage = (data: {packageId: number, user: number, hash: string}) => {
    return {
        type: PACKAGE_CONSTANTS.FINISH_INTERPRETATION_PACKAGE,
        payload: data
    }
};

export const getUpdateDataPackage = () => {
    return {
        type: PACKAGE_CONSTANTS.GET_UPDATE_DATA,
    }
};

export const sendMessageMailStartPackage = (data: {url: string, mail: string}) => {
    return {
        type: PACKAGE_CONSTANTS.SEND_MESSAGE_MAIL_START_PACKAGE,
        payload: data
    }
};
