import {PAGES} from "./constants";
import {IMetaPage} from "../../Base/helpers/get-data-page";

export interface IPageState {
    data: IMetaPage
}

const initialState: IPageState = {
    data: {
        title: '',
        name: '',
        description: '',
        keywords: '',
        content: '',
        url: '/',
    }
};

export const pageReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case PAGES.GET_DATA_REQUEST:
            return store
        case PAGES.GET_DATA_ANSWER:
            return {
                ...store,
                data: action.payload
            }
    }
    return store;
}
