import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531'
    },

})(CheckCircleOutlineIcon);

const CheckCircleOutlineIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default CheckCircleOutlineIconStyle;