//@ts-nocheck
import {call, put, select, takeLatest, debounce, delay} from "redux-saga/effects";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {TESTS_CONSTANTS} from "./constants";
import {
    newTestAnswer,
    getDataRunTestAnswer,
    getDataRunTestError,
    saveAnswerRunTestAnswer,
    finishTestAnswer,
    getListTestingAnswer, getListTestingRequest
} from "./actions";
import { getDataUserRequest } from "../../User/store/actions";
import {BaseState} from "../../Base/rootReducer";
import {ACTIVE_TEST} from "./type";
import {finishTestPackage} from "../../Package/store/actions";

function* newTestRequestSaga({payload}: any) {
    try {
        const request = yield call(getResource, '/newTesting/', METHOD_REQUEST.POST, {}, {data: payload});

        if (request.success) {
            const {hash} = request.success;
            yield put(newTestAnswer(hash))

            // Если создали тест для авторизированного пользователя, то запросим его данные заново. Могли поменять пол и фозраст пользователя на моменте старта теста
            if (payload.user) {
                yield put(getDataUserRequest())
            }
        }

        // if (request.error) {
        //     error = request.error.error_message;
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataRunTestSaga({payload}: any) {
    try {
        const token = yield select((state: BaseState) => state.auth.token);
        const {hash, code, testId, isCurator} = payload;

        const url = isCurator ? '/getDataCuratorTest/' : '/getDataTest/'
        const request = yield call(getResource, url, METHOD_REQUEST.POST, {"Authorization": "Token " + token}, {data: {hash, code, testId}});

        if (request.success) {
            const data = {
                ...request.success.data,
                id: Number(request.success.data.id),
                user: Number(request.success.data.user),
                time_limit: Number(request.success.data.time_limit),
                age: Number(request.success.data.age),
                sort: Number(request.success.data.sort),
                count_questions: Number(request.success.data.count_questions),
                id_active_question: Number(request.success.data.id_active_question),
            }
            yield put(getDataRunTestAnswer(data))
        }

        if (request.error) {
            yield put(getDataRunTestError(true));
        }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }

}

function* saveAnswerRunTestRequestSaga({payload}: any) {
    try {
        const runTest = yield select((state: BaseState) => state.test.runTest);
        const {value, time_limit, hash, id} = runTest;

        const url = payload ? '/setResultCuratorTests/' : '/setResultTests/';
        const request = yield call(getResource, url, METHOD_REQUEST.POST, {}, {data: {id, hash, value, time_limit}});

        if (request.success) {
            yield delay(100);
            yield put(saveAnswerRunTestAnswer());
        }

        // if (request.error) {
        //     yield put(getDataRunTestError(true));
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }

}

function* finishTestSaga ({payload}: any) {
    try {
        const runTest = yield select((state: BaseState) => state.test.runTest);

        const url = payload ? '/finishCuratorTest/' : '/finishTest/';

        const request = yield call(getResource, url, METHOD_REQUEST.POST, {}, {data: runTest});

        if (request.success) {
            const data = {
                ...runTest,
                date_finish: request.success.data.date_finish,
                result: request.success.data.result,
                finished: ACTIVE_TEST.ACTIVE,
            }
            yield put(finishTestPackage());
            yield put(getDataRunTestAnswer(data));
            yield put(finishTestAnswer());

        }

        // if (request.error) {
        //     yield put(getDataRunTestError(true));
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }

}

function* getListTestingSaga() {
    try {
        const token = yield select((state: BaseState) => state.auth.token);
        const request = yield call(getResource, '/listTesting/', METHOD_REQUEST.GET, {"Authorization": "Token " + token}, {});

        if (request.success) {
            yield put(getListTestingAnswer(request.success.data));
        }

        // if (request.error) {
        //     yield put(getDataRunTestError(true));
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }

}

function* deteteTestSaga({payload}: any) {
    try {
        const token = yield select((state: BaseState) => state.auth.token);
        const url = payload.type === 'test' ? '/deleteTest/' : '/deletePackage'
        yield call(getResource, url, METHOD_REQUEST.POST, {"Authorization": "Token " + token}, {data:{id: payload.id}});

        yield put(getListTestingRequest());

    } catch (error) {
        yield put(getListTestingRequest());
    }

}


export default function* watchTest() {
    yield takeLatest(TESTS_CONSTANTS.NEW_TEST_REQUEST, newTestRequestSaga);
    yield takeLatest(TESTS_CONSTANTS.GET_DATA_RUN_TEST_REQUEST, getDataRunTestSaga);
    yield takeLatest(TESTS_CONSTANTS.SAVE_ANSWER_RUN_TEST_REQUEST, saveAnswerRunTestRequestSaga);
    yield takeLatest(TESTS_CONSTANTS.FINISH_TEST_REQUEST, finishTestSaga);
    yield takeLatest(TESTS_CONSTANTS.GET_LIST_TESTING_REQUEST, getListTestingSaga);
    yield takeLatest(TESTS_CONSTANTS.DELETE_TEST_REQUEST, deteteTestSaga);
}