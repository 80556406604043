import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#dadada',
        '&:hover': {
            color: '#39b531'
        }
    }
})(CloseIcon);

const CloseIconStyle = () => {
    return (
        <Style />
    );
};


export default CloseIconStyle;
