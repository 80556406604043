import React, { Fragment, useState } from 'react';
// @ts-ignore
import { isMobile } from 'react-device-detect';
import style from './index.module.scss';
import mainMenu, { mainMenuInterface, MENU_LIST } from '../../constants/menu';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { BaseState } from '../../rootReducer';
import mainRoutes from '../../constants/routes';
import { isVisibleMenu } from './helpers';

interface IMainMenu {
    isAuth?: boolean,
    admin?: number,
    curator?: number
}

const MainMenu = (
        {
            isAuth,
            admin,
            curator
        }: IMainMenu) => {

        const [dataPopup, setDataPopup] = useState({
            id: 0,
            isOpen: false,
            left: 0
        });
        const [dataPopupSubMenu, setDataPopupSubMenu] = useState({
            id: 0,
            isOpen: false,
            left: 0,
            top: 0
        });

        const body = document.body;
        const menuList: mainMenuInterface[] = mainMenu.filter((menu) => menu.visible);
        const routes = mainRoutes();
        const history = useHistory();

        const arrayMain = () => {
            let array: number[] = [];
            menuList.forEach(elem => {
                if (history.location.pathname.indexOf(elem.url) !== -1) {
                    array.push(elem.id);
                    if (elem.parent) {
                        menuList.forEach(elem2 => {
                            if (elem2.id === elem.parent) {
                                array.push(elem2.id);
                            }
                        })
                    }

                }
            });
            return array;
        }

        const menuActiveUrl: number[] = arrayMain();

        const getCoords = (elem: HTMLDivElement) => { // кроме IE8-
            const box = elem.getBoundingClientRect();
            return {
                // eslint-disable-next-line no-restricted-globals
                top: box.top + pageYOffset,
                // eslint-disable-next-line no-restricted-globals
                left: box.left + pageXOffset
            };
        }

        const clickMenu = (id: MENU_LIST, event: React.MouseEvent) => {
            // @ts-ignore
            const coords = getCoords(event.target);
            setDataPopup({ id: id, isOpen: true, left: coords.left })
            body.onclick = (event) => {
                // @ts-ignore
                if (event.target.hasAttribute('menu') !== true) {
                    setDataPopup({ id: 0, isOpen: false, left: 0 })
                    setDataPopupSubMenu({ id: 0, isOpen: false, left: 0, top: 0 })
                }
            }
        }

        const listMainMenu = () => {

            return menuList
                .filter(elem => {
                    const admin = !isAuth && elem.isAuth ? false : true; // Если я не авторизировался,
                    // то админские пункты меню не нужно показывать

                    return !elem.parent && elem.visible && admin
                })
                .map(elem => {

                    if (menuList.filter(elem_1 => elem_1.parent === elem.id).length !== 0) {
                        return <div className={menuActiveUrl.indexOf(elem.id) !== -1
                            ? style.header_menu_button_active
                            : style.header_menu_button}
                                    onClick={(event) => {
                                        clickMenu(elem.id, event)
                                    }}
                                    key={elem.id}
                        >
                            {elem.menuName}
                            {elem.nested
                                ? <div
                                    className={
                                        menuActiveUrl.indexOf(elem.id) !== -1
                                            ? style.ico_menu_active
                                            : style.ico_menu
                                    }>

                                </div>
                                : null}
                        </div>
                    } else {
                        return <Link to={elem.url} key={elem.id} className={menuActiveUrl.indexOf(elem.id) !== -1
                            ? style.header_menu_button_active
                            : style.header_menu_button}>
                            {elem.menuName}
                        </Link>
                    }

                })
        }

        const closePopups = () => {
            setDataPopup({ id: 0, isOpen: false, left: 0 });
            setDataPopupSubMenu({ id: 0, isOpen: false, left: 0, top: 0 });
        }

        const popupMenu = () => {
            if (dataPopup.isOpen) {
                // @ts-ignore
                return <div className={style.header_submenu} style={{ left: dataPopup.left + 'px' }} menu={'menu'}>
                    {
                        menuList
                            .filter((el) => isVisibleMenu(el, dataPopup.id, !!admin, !!curator))
                            .map((elem, key) => {
                                if (isMobile) {
                                    return (
                                        <Fragment key={elem.id}>
                                            <div
                                                className={menuActiveUrl.indexOf(elem.id) !== -1
                                                    ? style.header_submenu_menu_button_container_active
                                                    : style.header_submenu_menu_button_container}
                                                // @ts-ignore
                                                menu={elem.id}
                                                onClick={(event) => {
                                                    clickSubMenu(elem.id, event);
                                                    event.stopPropagation();
                                                }}
                                            >
                                                {elem.menuName}
                                                <div className={menuActiveUrl.indexOf(elem.id) !== -1
                                                    ? style.header_submenu_menu_ico_active
                                                    : style.header_submenu_menu_ico}
                                                    // @ts-ignore
                                                     menu={elem.id}
                                                >

                                                </div>
                                            </div>
                                            {
                                                menuList
                                                    .filter(elem => elem.parent === dataPopup.id).length - 1 !== key &&
                                                <div className={style.header_submenu_border}/>
                                            }
                                            {popupSubMenu()}
                                        </Fragment>
                                    )
                                } else {

                                    return <Fragment key={elem.id}>
                                        <Link
                                            to={elem.url}
                                            onClick={() => {
                                                closePopups()
                                            }}
                                            onMouseMove={(event) => {
                                                clickSubMenu(elem.id, event)
                                            }}
                                            onMouseOver={() => {
                                                setDataPopupSubMenu({ id: 0, isOpen: false, left: 0, top: 0 })
                                            }}
                                            className={menuActiveUrl.indexOf(elem.id) !== -1
                                                ? style.header_submenu_menu_button_container_active
                                                : style.header_submenu_menu_button_container}
                                            // @ts-ignore
                                            menu={elem.id}
                                        >
                                            {elem.menuName}
                                            {
                                                elem.nested
                                                && <div className={menuActiveUrl.indexOf(elem.id) !== -1
                                                    ? style.header_submenu_menu_ico_active
                                                    : style.header_submenu_menu_ico}
                                                    // @ts-ignore
                                                        menu={elem.id}
                                                >
                                                </div>
                                            }
                                        </Link>
                                        {
                                            menuList
                                                .filter(elem => elem.parent === dataPopup.id).length - 1 !== key &&
                                            <div className={style.header_submenu_border}/>
                                        }
                                        {elem.nested && popupSubMenu()}
                                    </Fragment>
                                }
                            })
                    }
                </div>
            }
            return null;
        }

        const popupSubMenu = () => {
            if (dataPopupSubMenu.isOpen) {
                return <div
                    className={style.header_menu_test_container}
                    style={{ left: 345 + 'px', top: dataPopupSubMenu.top - 150 + 'px' }}
                    // @ts-ignore
                    menu={'submenu'}
                >
                    {
                        menuList.filter(elem => elem.parent === dataPopupSubMenu.id).map((elem, key) => {
                            return (
                                <Fragment key={elem.id}>
                                    <Link
                                        to={elem.url}
                                        onClick={() => {
                                            closePopups()
                                        }}
                                        className={menuActiveUrl.indexOf(elem.id) !== -1
                                            ? style.header_menu_test_active
                                            : style.header_menu_test}
                                        // @ts-ignore
                                        menu={elem.id}
                                    >
                                        {elem.name}
                                    </Link>
                                    {
                                        menuList
                                            .filter(elem => elem.parent === dataPopupSubMenu.id).length - 1 !== key &&
                                        <div className={style.header_submenu_border_2}/>
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
            }
            return null;

        }

        const clickSubMenu = (id: MENU_LIST, event: React.MouseEvent) => {
            // @ts-ignore
            const coords = getCoords(event.target);
            setDataPopupSubMenu({ id: id, isOpen: true, left: coords.left, top: coords.top })
            event.stopPropagation();
        }

        return (
            <Fragment>
                {listMainMenu()}
                {popupMenu()}
            </Fragment>

        );
    }
;

const mapStateToProps = (state: BaseState) => {
    return {
        isAuth: state.auth.isAuth,
        admin: state.user.data.admin,
        curator: state.user.data.curator,
    }
}

export default connect(mapStateToProps, null)(MainMenu);
