import mainRoutes from "./routes";
import {IMetaPage} from "../helpers/get-data-page";

export interface mainMenuInterface {
    id: MENU_LIST,
    url: string,
    menuName: string,
    title: string,
    name: string,
    description: string,
    keywords: string,
    parent: MENU_LIST, // Родитель
    nested: boolean, // Имеет ли детей (необходимо для стилей)
    visible: boolean, // Показывать в основном меню
    sitemap: boolean, // Показывать в карте сайта
    isAuth: boolean, // требуется авторизация
}

const routes = mainRoutes();

const notUrl = '/#';

export enum MENU_LIST {
    NONE = 0,
    // Основные разделы
    MAIN = 1,
    SERVICES = 2,
    TESTS = 3,
    USEFUL = 4,
    PAGE_404 = 404,
    SITEMAP = 100,
    POLITIKA_KONFIDENCIALNOSTI = 101,
    CHANGE_PASSWORD = 102,
    CONTRACT_OFFER = 103,

    // Разделы 2 уровня
    PACKAGE = 6,
    ONLINE_COURSE = 7,
    COMPLEX_PERSONALITY_TESTS = 9,
    ONE_FACTOR_PERSONALITY_TESTS = 10,
    PROJECTIVE_TESTS = 11,
    THE_IQ_TESTS = 12,
    PROFESSIONAL_QUALITY_TESTS = 13,
    TESTS_ON_THE_TYPE_OF_INTERPERSONAL_RELATIONSHIPS = 14,
    COMPLEX_TESTING = 15,
    CATALOGUE_OF_PROFESSIONS = 16,
    ARTICLES = 17,

    // Разделы 3 уровня
    AKCENT = 18,
    KETTEL = 19,
    MMIL = 20,
    AIZENK = 21,
    EMOTIONAL_INTELLIGENCE = 22,
    LYUSHER = 23,
    SONDI = 24,
    KOT = 25,
    MAP_OF_INTERESTS = 26,
    SHUTC = 27,
    SELF_DEVELOPMENT_TEST = 28,
    SELF_REALIZATION_TEST = 29,
    KEIRSEY =  30,
    BDHI = 31,
    GERCHIKOV = 32,



    // Админка
    SYSTEMS = 1000,
    ACCOUNT = 1001,
    MY_TESTING = 1002,
    ADMIN = 1003,
    MY_ONLINE_COURSE = 1004,

    // Кураторы
    CURATORS = 2000,
    TESTED = 2001,
    EXAMS = 2002,
    CURATORS_TESTING = 2003,
    CURATOR_TELEGRAM = 2004,
}

const mainMenu: Array<mainMenuInterface> = [
    {
        id: MENU_LIST.MAIN,
        url: routes.main.url,
        menuName: routes.main.title,
        title: 'Сервис профессионального психологического тестирования',
        name: 'Саморазвитие онлайн: раскройте свой потенциал',
        description: 'Профессиональные психологические тесты с точностью от 80%. Помогут понять себя и улучшить качество своей жизни. Тестирование занимает около 30 минут, а заключение по результатам тестов стоит 590 рублей.',
        keywords: 'Сервис, тестирование, тест, онлайн, психологического, профотбор, профориентация, профессионального.',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.TESTS,
        url: notUrl,
        menuName: 'Тестирование',
        title: 'Тестирование',
        name: 'Тестирование',
        description: 'Тестирование',
        keywords: 'Тестирование',
        parent: MENU_LIST.NONE,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.USEFUL,
        url: notUrl,
        menuName: 'Полезное',
        title: 'Полезное',
        name: 'Полезное',
        description: 'Полезное',
        keywords: 'Полезное',
        parent: MENU_LIST.NONE,
        nested: true,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.PAGE_404,
        url: routes['404'].url,
        menuName: routes['404'].title,
        title: '404. Страница не найдена',
        name: '404. Страница не найдена',
        description: '404. Страница не найдена',
        keywords: '404. Страница не найдена',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
        url: routes['complex_personality_tests'].url,
        menuName: routes['complex_personality_tests'].title,
        title: 'Многофакторные личностные тесты',
        name: 'Многофакторные личностные тесты',
        description: 'Комплексные личностные тесты представлены многофакторные опросники, направленные на изучение всех личностных особенностей человека, призванные раскрыть все грани его характера и дать полный психологический портрет личности.',
        keywords: 'Многофакторные, личностные, тест, Акцент, Кеттел, ММИЛ, онлайн.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
        url: routes['one_factor_personality_tests'].url,
        menuName: routes['one_factor_personality_tests'].title,
        title: 'Однофакторные личностные тесты',
        name: 'Однофакторные личностные тесты',
        description: 'В разделе однофакторные личностные тесты мы собрали методики, измеряющие какую-либо одну грань личности.',
        keywords: 'Однофакторные, личностные, тесты, онлайн, эмоциональный интеллект, Айзенк.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.PROJECTIVE_TESTS,
        url: routes['projective_tests'].url,
        menuName: routes['projective_tests'].title,
        title: 'Проективные тесты',
        name: 'Проективные тесты',
        description: 'Проективные методики основаны на понятии «проекция». Проекция – это один из видов психологических защит, сформулированных З.Фрейдом.',
        keywords: 'Проективные, тесты, онлайн, Сонди, Люшер.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.THE_IQ_TESTS,
        url: routes['the_iq_tests'].url,
        menuName: routes['the_iq_tests'].title,
        title: 'Тесты на интеллект',
        name: 'Тесты на интеллект',
        description: 'Этот раздел объединил различного рода тесты на определение уровня интеллектуальных способностей.',
        keywords: 'Тест, интеллект, онлайн, кот.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.PROFESSIONAL_QUALITY_TESTS,
        url: routes['professional_quality_tests'].url,
        menuName: routes['professional_quality_tests'].title,
        title: 'Тесты на профессиональные качества',
        name: 'Тесты на профессиональные качества',
        description: 'В этом разделе представлены методики, помогающие исследовать профессиональные качества личности.',
        keywords: 'Тест, профессиональные, качества, карта интересов, онлайн.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.TESTS_ON_THE_TYPE_OF_INTERPERSONAL_RELATIONSHIPS,
        url: routes['tests_on_the_type_of_interpersonal_relationships'].url,
        menuName: routes['tests_on_the_type_of_interpersonal_relationships'].title,
        title: 'Тесты на тип межличностных отношений',
        name: 'Тесты на тип межличностных отношений',
        description: 'В данном разделе представлены тесты, описывающие типы межличностного взаимодействия между людьми.',
        keywords: 'Тест, тип,  межличностных, отношений, Шутц, онлайн.',
        parent: MENU_LIST.TESTS,
        nested: true,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.COMPLEX_TESTING,
        url: routes['complex_testing'].url,
        menuName: routes['complex_testing'].title,
        title: 'Комплексное тестирование',
        name: 'Комплексное тестирование',
        description: 'Комплексное тестирование',
        keywords: 'Комплексное тестирование',
        parent: MENU_LIST.TESTS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.CATALOGUE_OF_PROFESSIONS,
        url: routes['catalogue_of_professions'].url,
        menuName: routes['catalogue_of_professions'].title,
        title: 'Каталог профессий',
        name: 'Каталог профессий',
        description: 'Каталог профессий',
        keywords: 'Каталог профессий',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.ARTICLES,
        url: routes['articles'].url,
        menuName: routes['articles'].title,
        title: 'Статьи',
        name: 'Статьи',
        description: 'Статьи',
        keywords: 'Статьи',
        parent: MENU_LIST.USEFUL,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.AKCENT,
        url: routes['complex_personality_tests/akcent'].url,
        menuName: routes['complex_personality_tests/akcent'].title,
        title: 'Акцент 2-90',
        name: 'Акцент 2-90',
        description: 'Многофакторный личностный тест',
        keywords: 'Акцент, 2-90, онлайн, тест, тестирование, результат, Вигдорчик, акцентуация, интерпретация, прохождение.',
        parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.KETTEL,
        url: routes['complex_personality_tests/kettel'].url,
        menuName: routes['complex_personality_tests/kettel'].title,
        title: '16-факторный личностный опросник Кеттела (или 16 PF)',
        name: 'Опросник Р. Кеттела 16PF',
        description: 'Многофакторный личностный тест',
        keywords: 'Кеттел, опросник, 16PF, факторный, 16-факторный, тест, тестирование, онлайн, результат, интерпретация.',
        parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.MMIL,
        url: routes['complex_personality_tests/mmil'].url,
        menuName: routes['complex_personality_tests/mmil'].title,
        title: 'Методика многостороннего исследования личности - ММИЛ (MMPI)',
        name: 'ММИЛ (MMPI)',
        description: 'Многофакторный личностный тест',
        keywords: 'ММИЛ, MMPI, тест, онлайн, прохождение, результат, интерпретация, прохождение.',
        parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.KEIRSEY,
        url: routes['complex_personality_tests/keirsey'].url,
        menuName: routes['complex_personality_tests/keirsey'].title,
        title: 'Опросник Кейрси',
        name: 'Опросник Кейрси',
        description: 'Многофакторный личностный тест',
        keywords: 'Кейрси, опросник, keirsey, тест, онлайн, прохождение, результат, интерпретация, прохождение.',
        parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.AIZENK,
        url: routes['one_factor_personality_tests/aizenk'].url,
        menuName: routes['one_factor_personality_tests/aizenk'].title,
        title: 'Личностный тест Айзенка (EPI - Eysenck Personality Inventory)',
        name: 'Тест Г. Айзенка (EPI)',
        description: 'Однофакторный личностый тест',
        keywords: 'Айзенк, EPI, опросник, экстраверсия, интроверсия, нейротизм, тест, онлайн, прохождение, результат, интерпретация, прохождение.',
        parent: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.BDHI,
        url: routes['one_factor_personality_tests/bdhi'].url,
        menuName: routes['one_factor_personality_tests/bdhi'].title,
        title: 'Опросник агрессивности Басса-Дарки (BDHI)',
        name: 'Опросник агрессивности Басса-Дарки ',
        description: 'Однофакторный личностый тест',
        keywords: 'Опросник агрессивности басса-дарки, басса-дарки, тест, bdhi, онлайн, прохождение, результат, интерпретация.',
        parent: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.EMOTIONAL_INTELLIGENCE,
        url: routes['one_factor_personality_tests/emotional_intelligence'].url,
        menuName: routes['one_factor_personality_tests/emotional_intelligence'].title,
        title: 'Эмоциональный интеллект или коэффициент EQ',
        name: 'Тест на уровень развития эмоционального интеллекта',
        description: 'Однофакторный личностый тест',
        keywords: 'Эмоциональный интеллект, коэффииент, EQ, тест, онлайн, прохождение, результат, интерпретация.',
        parent: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.LYUSHER,
        url: routes['projective_tests/lyusher'].url,
        menuName: routes['projective_tests/lyusher'].title,
        title: 'Восьмицветовой тест М. Люшера',
        name: 'Цветовой тест М. Люшера',
        description: 'Проективный тест',
        keywords: 'Люшер, цветовой, восьмиветовой, тест, онлайн, результат, интерпретация, прохождение.',
        parent: MENU_LIST.PROJECTIVE_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.SONDI,
        url: routes['projective_tests/sondi'].url,
        menuName: routes['projective_tests/sondi'].title,
        title: 'Метод портретных выборов Л. Сонди',
        name: 'Метод портретных выборов Л. Сонди',
        description: 'Проективный тест',
        keywords: 'Сонди, портретный, выбор, метод, тест, онлайн, результат, интерпретация, прохождение.',
        parent: MENU_LIST.PROJECTIVE_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.KOT,
        url: routes['the_iq_tests/kot'].url,
        menuName: routes['the_iq_tests/kot'].title,
        title: 'Тест на интеллект - К.О.Т.',
        name: 'К.О.Т.',
        description: 'Тест на интеллект',
        keywords: 'кот, к.о.т., методика, тест, онлайн, результат, интерпретация, прохождение.',
        parent: MENU_LIST.THE_IQ_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.MAP_OF_INTERESTS,
        url: routes['professional_quality_tests/map_of_interests'].url,
        menuName: routes['professional_quality_tests/map_of_interests'].title,
        title: 'Карта интересов',
        name: 'Карта интересов',
        description: 'Тест на профессиональные качества',
        keywords: 'Карта интересов, карта, интересов, профориентация, тест, прохождение, онлайн, результат, интерпретация.',
        parent: MENU_LIST.PROFESSIONAL_QUALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.GERCHIKOV,
        url: routes['professional_quality_tests/gerchikov'].url,
        menuName: routes['professional_quality_tests/gerchikov'].title,
        title: 'Тест оценки мотивации Герчикова (Motype)',
        name: 'Тест оценки мотивации Герчикова',
        description: 'Тест на профессиональные качества',
        keywords: 'тест, оценка, оценки, мотивация, Герчеков, онлайн, пройти, прохождение, результат, интерпретация',
        parent: MENU_LIST.PROFESSIONAL_QUALITY_TESTS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.SHUTC,
        url: routes['tests_on_the_type_of_interpersonal_relationships/shutc'].url,
        menuName: routes['tests_on_the_type_of_interpersonal_relationships/shutc'].title,
        title: 'Опросник межличностных отношений В. Шутца (FIRO)',
        name: 'ОМО В. Шутца (FIRO)',
        description: 'Тест на тип межличностных отношений',
        keywords: 'Шутц, FIRO, опросник, межличностных, отношений, тест, онлайн, прохождение, результат, интерпретация.',
        parent: MENU_LIST.TESTS_ON_THE_TYPE_OF_INTERPERSONAL_RELATIONSHIPS,
        nested: false,
        visible: true,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.SELF_DEVELOPMENT_TEST,
        url: routes['complex_testing/self_development_test'].url,
        menuName: routes['complex_testing/self_development_test'].title,
        title: 'Тестирование на саморазвитие',
        name: 'Тестирование на саморазвитие',
        description: 'Тестирование на саморазвитие',
        keywords: 'Тестирование на саморазвитие',
        parent: MENU_LIST.COMPLEX_TESTING,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.SELF_REALIZATION_TEST,
        url: routes['complex_testing/self_realization_test'].url,
        menuName: routes['complex_testing/self_realization_test'].title,
        title: 'Тестирование на самореализацию',
        name: 'Тестирование на самореализацию',
        description: 'Тестирование на самореализацию',
        keywords: 'Тестирование на самореализацию',
        parent: MENU_LIST.COMPLEX_TESTING,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.SITEMAP,
        url: routes['sitemap'].url,
        menuName: routes['sitemap'].title,
        title: 'Карта сайта',
        name: 'Карта сайта',
        description: 'Карта сайта',
        keywords: 'Карта сайта',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.POLITIKA_KONFIDENCIALNOSTI,
        url: routes['politika_konfidencialnosti'].url,
        menuName: routes['politika_konfidencialnosti'].title,
        title: 'Политика конфиденциальности',
        name: 'Политика конфиденциальности',
        description: 'Политика конфиденциальности',
        keywords: 'Политика конфиденциальности',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.CHANGE_PASSWORD,
        url: routes.change.url,
        menuName: routes.change.title,
        title: 'Востановление пароля',
        name: 'Востановление пароля',
        description: 'Востановление пароля',
        keywords: 'Востановление пароля',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },
    {
        id: MENU_LIST.SYSTEMS,
        url: notUrl,
        menuName: 'Кабинет',
        title: 'Кабинет',
        name: 'Кабинет',
        description: 'Кабинет',
        keywords: 'Кабинет',
        parent: MENU_LIST.NONE,
        nested: true,
        visible: true,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.ACCOUNT,
        url: routes.account.url,
        menuName: 'Профиль',
        title: 'Профиль',
        name: 'Профиль',
        description: 'Профиль',
        keywords: 'Профиль',
        parent: MENU_LIST.SYSTEMS,
        nested: false,
        visible: true,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.MY_TESTING,
        url: routes.my_testing.url,
        menuName: 'Моё тестирование',
        title: 'Моё тестирование',
        name: 'Моё тестирование',
        description: 'Моё тестирование',
        keywords: 'Моё тестирование',
        parent: MENU_LIST.SYSTEMS,
        nested: false,
        visible: true,
        sitemap: false,
        isAuth: true,
    },
    // {
    //     id: MENU_LIST.MY_ONLINE_COURSE,
    //     url: routes.my_online_course.url,
    //     menuName: 'Мой онлайн-курс',
    //     title: 'Мой онлайн-курс',
    //     name: 'Мой онлайн-курс',
    //     description: 'Мой онлайн-курс',
    //     keywords: 'Мой онлайн-курс',
    //     parent: MENU_LIST.SYSTEMS,
    //     nested: false,
    //     visible: true,
    //     sitemap: false,
    //     isAuth: true,
    // },
    // {
    //     id: MENU_LIST.ADMIN,
    //     url: routes.admin.url,
    //     menuName: 'Админка',
    //     title: 'Админка',
    //     name: 'Админка',
    //     description: 'Админка',
    //     keywords: 'Админка',
    //     parent: MENU_LIST.SYSTEMS,
    //     nested: false,
    //     visible: true,
    //     sitemap: false,
    //     isAuth: true,
    // },
    {
        id: MENU_LIST.CONTRACT_OFFER,
        url: routes.contract_offer.url,
        menuName: routes.contract_offer.title,
        title: 'Договор-оферта',
        name: 'Договор-оферта',
        description: 'Договор-оферта',
        keywords: 'Договор-оферта',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: true,
        isAuth: false,
    },
    {
        id: MENU_LIST.CURATORS,
        url: routes.curators.url,
        menuName: 'Куратор',
        title: 'Куратор',
        name: 'Куратор',
        description: 'Куратор',
        keywords: 'Куратор',
        parent: MENU_LIST.SYSTEMS,
        nested: false,
        visible: true,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.TESTED,
        url: routes.tested.url,
        menuName: 'Тестируемые',
        title: 'Тестируемые',
        name: 'Тестируемые',
        description: 'Тестируемые',
        keywords: 'Тестируемые',
        parent: MENU_LIST.CURATORS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.EXAMS,
        url: routes.exams.url,
        menuName: 'Обследования',
        title: 'Обследования',
        name: 'Обследования',
        description: 'Обследования',
        keywords: 'Обследования',
        parent: MENU_LIST.CURATORS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.CURATORS_TESTING,
        url: routes.curators_testing.url,
        menuName: 'Тестирование',
        title: 'Тестирование',
        name: 'Тестирование',
        description: 'Тестирование',
        keywords: 'Тестирование',
        parent: MENU_LIST.CURATORS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.CURATOR_TELEGRAM,
        url: routes.curators_telegram.url,
        menuName: 'Уведомления',
        title: 'Уведомления',
        name: 'Уведомления',
        description: 'Уведомления',
        keywords: 'Уведомления',
        parent: MENU_LIST.CURATORS,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: true,
    },
    {
        id: MENU_LIST.PACKAGE,
        url: routes.package.url,
        menuName: routes.package.title,
        title: 'Психологический портрет вашей личности',
        name: 'Психологический портрет вашей личности',
        description: 'Психологический портрет вашей личности',
        keywords: 'Психологический портрет вашей личности',
        parent: MENU_LIST.NONE,
        nested: false,
        visible: false,
        sitemap: false,
        isAuth: false,
    },


]

export const defaultDataMenu = {
    id: MENU_LIST.PAGE_404,
    url: routes['404'].url,
    menuName: routes['404'].title,
    title: '',
    name: '',
    description: '',
    keywords: '',
    parent: MENU_LIST.NONE,
    nested: false,
    visible: false,
};

export const defaultDataPage: IMetaPage = {
    title: '',
    name: '',
    description: '',
    keywords: '',
    content: '',
    url: '/'
};

// export const xxx = new Map<MENU_LIST, mainMenuInterface>([
//     [MENU_LIST.MAIN, {
//         id: MENU_LIST.MAIN,
//         url: routes.main.url,
//         menuName: routes.main.title,
//         title: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         name: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         description: 'Профессиональная психологическая помощь в поиске своего дела и смене профессии.',
//         keywords: 'Сервис, тестирование, тест, онлайн, психологического, профотбор, профориентация.',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     }],
//     [MENU_LIST.SERVICES, {
//         id: MENU_LIST.SERVICES,
//         url: notUrl,
//         menuName: 'Услуги',
//         title: '',
//         name: '',
//         description: '',
//         keywords: '',
//         parent: MENU_LIST.NONE,
//         nested: true,
//         visible: true,
//     }],
//     [MENU_LIST.TESTS, {
//         id: MENU_LIST.TESTS,
//         url: notUrl,
//         menuName: 'Тестирование',
//         title: '',
//         name: '',
//         description: '',
//         keywords: '',
//         parent: MENU_LIST.NONE,
//         nested: true,
//         visible: true,
//     }],
//     [MENU_LIST.USEFUL, {
//         id: MENU_LIST.USEFUL,
//         url: notUrl,
//         menuName: 'Полезное',
//         title: '',
//         name: '',
//         description: '',
//         keywords: '',
//         parent: MENU_LIST.NONE,
//         nested: true,
//         visible: false,
//     }],
//     [MENU_LIST.PAGE_404, {
//         id: MENU_LIST.PAGE_404,
//         url: routes['404'].url,
//         menuName: routes['404'].title,
//         title: '404. Страница не найдена',
//         name: '404. Страница не найдена',
//         description: '404. Страница не найдена',
//         keywords: '404. Страница не найдена',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     }],
//     [MENU_LIST.SELF_REALIZATION, {
//         id: MENU_LIST.SELF_REALIZATION,
//         url: routes['self_realization'].url,
//         menuName: routes['self_realization'].title,
//         title: 'Самореализация',
//         name: 'Самореализация',
//         description: 'Самореализация',
//         keywords: 'Самореализация',
//         parent: MENU_LIST.SERVICES,
//         nested: false,
//         visible: true,
//     }],
//     [MENU_LIST.SELF_DEVELOPMENT, {
//         id: MENU_LIST.SELF_DEVELOPMENT,
//         url: routes['self_development'].url,
//         menuName: routes['self_development'].title,
//         title: 'Саморазвитие',
//         name: 'Саморазвитие',
//         description: 'Саморазвитие',
//         keywords: 'Саморазвитие',
//         parent: MENU_LIST.SERVICES,
//         nested: false,
//         visible: true,
//     }],
//     [MENU_LIST.ONLINE_COURSE, {
//         id: MENU_LIST.ONLINE_COURSE,
//         url: routes['online_course'].url,
//         menuName: routes['online_course'].title,
//         title: 'Онлайн-курс',
//         name: 'Онлайн-курс',
//         description: 'Онлайн-курс',
//         keywords: 'Онлайн-курс',
//         parent: MENU_LIST.SERVICES,
//         nested: false,
//         visible: true,
//     }],
//     [MENU_LIST.COMPLEX_PERSONALITY_TESTS, {
//         id: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
//         url: routes['complex_personality_tests'].url,
//         menuName: routes['complex_personality_tests'].title,
//         title: 'Комплексные личностные тесты',
//         name: 'Комплексные личностные тесты',
//         description: 'Комплексные личностные тесты представлены многофакторные опросники, направленные на изучение всех личностных особенностей человека, призванные раскрыть все грани его характера и дать полный психологический портрет личности.',
//         keywords: 'Комплексные, личностные, тест, Акцент, Кеттел, ММИЛ, онлайн.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     }],
//     [MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS, {
//         id: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
//         url: routes['complex_personality_tests'].url,
//         menuName: routes['complex_personality_tests'].title,
//         title: 'Комплексные личностные тесты',
//         name: 'Комплексные личностные тесты',
//         description: 'Комплексные личностные тесты представлены многофакторные опросники, направленные на изучение всех личностных особенностей человека, призванные раскрыть все грани его характера и дать полный психологический портрет личности.',
//         keywords: 'Комплексные, личностные, тест, Акцент, Кеттел, ММИЛ, онлайн.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     }],
//
// ]);
// [MENU_LIST.MAIN, {
//     id: MENU_LIST.MAIN,
//     url: routes.main.url,
//     menuName: routes.main.title,
//     title: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//     name: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//     description: 'Профессиональная психологическая помощь в поиске своего дела и смене профессии.',
//     keywords: 'Сервис, тестирование, тест, онлайн, психологического, профотбор, профориентация.',
//     parent: MENU_LIST.NONE,
//     nested: false,
//     visible: false,
// }],
//     [MENU_LIST.MAIN, {
//         id: MENU_LIST.MAIN,
//         url: routes.main.url,
//         menuName: routes.main.title,
//         title: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         name: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         description: 'Профессиональная психологическая помощь в поиске своего дела и смене профессии.',
//         keywords: 'Сервис, тестирование, тест, онлайн, психологического, профотбор, профориентация.',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     }],
//     [MENU_LIST.MAIN, {
//         id: MENU_LIST.MAIN,
//         url: routes.main.url,
//         menuName: routes.main.title,
//         title: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         name: 'PROF-ASSISTANT -  Ваш помощник в самореализации и поиске призвания',
//         description: 'Профессиональная психологическая помощь в поиске своего дела и смене профессии.',
//         keywords: 'Сервис, тестирование, тест, онлайн, психологического, профотбор, профориентация.',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     }],
//
//
//     {
//         id: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
//         url: routes['one_factor_personality_tests'].url,
//         menuName: routes['one_factor_personality_tests'].title,
//         title: 'Однофакторные личностные тесты',
//         name: 'Однофакторные личностные тесты',
//         description: 'В разделе однофакторные личностные тесты мы собрали методики, измеряющие какую-либо одну грань личности.',
//         keywords: 'Однофакторные, личностные, тесты, онлайн, эмоциональный интеллект, Айзенк.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.PROJECTIVE_TESTS,
//         url: routes['projective_tests'].url,
//         menuName: routes['projective_tests'].title,
//         title: 'Проективные тесты',
//         name: 'Проективные тесты',
//         description: 'Проективные методики основаны на понятии «проекция». Проекция – это один из видов психологических защит, сформулированных З.Фрейдом.',
//         keywords: 'Проективные, тесты, онлайн, Сонди, Люшер.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.THE_IQ_TESTS,
//         url: routes['the_iq_tests'].url,
//         menuName: routes['the_iq_tests'].title,
//         title: 'Тесты на интеллект',
//         name: 'Тесты на интеллект',
//         description: 'Этот раздел объединил различного рода тесты на определение уровня интеллектуальных способностей.',
//         keywords: 'Тест, интеллект, онлайн, кот.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.PROFESSIONAL_QUALITY_TESTS,
//         url: routes['professional_quality_tests'].url,
//         menuName: routes['professional_quality_tests'].title,
//         title: 'Тесты на профессиональные качества',
//         name: 'Тесты на профессиональные качества',
//         description: 'В этом разделе представлены методики, помогающие исследовать профессиональные качества личности.',
//         keywords: 'Тест, профессиональные, качества, карта интересов, онлайн.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.TESTS_ON_THE_TYPE_OF_INTERPERSONAL_RELATIONSHIPS,
//         url: routes['tests_on_the_type_of_interpersonal_relationships'].url,
//         menuName: routes['tests_on_the_type_of_interpersonal_relationships'].title,
//         title: 'Тесты на тип межличностных отношений',
//         name: 'Тесты на тип межличностных отношений',
//         description: 'В данном разделе представлены тесты, описывающие типы межличностного взаимодействия между людьми.',
//         keywords: 'Тест, тип,  межличностных, отношений, Шутц, онлайн.',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.COMPLEX_TESTING,
//         url: routes['complex_testing'].url,
//         menuName: routes['complex_testing'].title,
//         title: 'Комплексное тестирование',
//         name: 'Комплексное тестирование',
//         description: 'Комплексное тестирование',
//         keywords: 'Комплексное тестирование',
//         parent: MENU_LIST.TESTS,
//         nested: true,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.CATALOGUE_OF_PROFESSIONS,
//         url: routes['catalogue_of_professions'].url,
//         menuName: routes['catalogue_of_professions'].title,
//         title: 'Каталог профессий',
//         name: 'Каталог профессий',
//         description: 'Каталог профессий',
//         keywords: 'Каталог профессий',
//         parent: MENU_LIST.USEFUL,
//         nested: false,
//         visible: false,
//     },
//     {
//         id: MENU_LIST.ARTICLES,
//         url: routes['articles'].url,
//         menuName: routes['articles'].title,
//         title: 'Статьи',
//         name: 'Статьи',
//         description: 'Статьи',
//         keywords: 'Статьи',
//         parent: MENU_LIST.USEFUL,
//         nested: false,
//         visible: false,
//     },
//     {
//         id: MENU_LIST.AKCENT,
//         url: routes['complex_personality_tests/akcent'].url,
//         menuName: routes['complex_personality_tests/akcent'].title,
//         title: 'Акцент 2-90',
//         name: 'Акцент 2-90',
//         description: 'Комплексный личностный тест',
//         keywords: 'Акцент, 2-90, онлайн, тест, тестирование, результат, Вигдорчик, акцентуация, интерпретация, прохождение.',
//         parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.KETTEL,
//         url: routes['complex_personality_tests/kettel'].url,
//         menuName: routes['complex_personality_tests/kettel'].title,
//         title: '16-факторный личностный опросник Кеттела (или 16 PF)',
//         name: 'Опросник Р. Кеттела 16PF',
//         description: 'Комплексный личностный тест',
//         keywords: 'Кеттел, опросник, 16PF, факторный, 16-факторный, тест, тестирование, онлайн, результат, интерпретация.',
//         parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.MMIL,
//         url: routes['complex_personality_tests/mmil'].url,
//         menuName: routes['complex_personality_tests/mmil'].title,
//         title: 'Методика многостороннего исследования личности - ММИЛ (MMPI)',
//         name: 'ММИЛ (MMPI)',
//         description: 'Комплексный личностный тест',
//         keywords: 'ММИЛ, MMPI, тест, онлайн, прохождение, результат, интерпретация, прохождение.',
//         parent: MENU_LIST.COMPLEX_PERSONALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.AIZENK,
//         url: routes['one_factor_personality_tests/aizenk'].url,
//         menuName: routes['one_factor_personality_tests/aizenk'].title,
//         title: 'Личностный опросник Айзенка (EPI - Eysenck Personality Inventory)',
//         name: 'Опросник Г. Айзенка (EPI)',
//         description: 'Однофакторный личностый тест',
//         keywords: 'Айзенк, EPI, опросник, экстраверсия, интроверсия, нейротизм, тест, онлайн, прохождение, результат, интерпретация, прохождение.',
//         parent: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.EMOTIONAL_INTELLIGENCE,
//         url: routes['one_factor_personality_tests/emotional_intelligence'].url,
//         menuName: routes['one_factor_personality_tests/emotional_intelligence'].title,
//         title: 'Эмоциональный интеллект или коэффициент EQ',
//         name: 'Тест на уровень развития эмоционального интеллекта',
//         description: 'Однофакторный личностый тест',
//         keywords: 'Эмоциональный интеллект, коэффииент, EQ, тест, онлайн, прохождение, результат, интерпретация.',
//         parent: MENU_LIST.ONE_FACTOR_PERSONALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.LYUSHER,
//         url: routes['projective_tests/lyusher'].url,
//         menuName: routes['projective_tests/lyusher'].title,
//         title: 'Восьмицветовой тест М. Люшера',
//         name: 'Цветовой тест М. Люшера',
//         description: 'Проективный тест',
//         keywords: 'Люшер, цветовой, восьмиветовой, тест, онлайн, результат, интерпретация, прохождение.',
//         parent: MENU_LIST.PROJECTIVE_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.SONDI,
//         url: routes['projective_tests/sondi'].url,
//         menuName: routes['projective_tests/sondi'].title,
//         title: 'Метод портретных выборов Л. Сонди',
//         name: 'Метод портретных выборов Л. Сонди',
//         description: 'Проективный тест',
//         keywords: 'Сонди, портретный, выбор, метод, тест, онлайн, результат, интерпретация, прохождение.',
//         parent: MENU_LIST.PROJECTIVE_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.KOT,
//         url: routes['the_iq_tests/kot'].url,
//         menuName: routes['the_iq_tests/kot'].title,
//         title: 'Тест на интеллект - К.О.Т.',
//         name: 'К.О.Т.',
//         description: 'Тест на интеллект',
//         keywords: 'кот, к.о.т., методика, тест, онлайн, результат, интерпретация, прохождение.',
//         parent: MENU_LIST.THE_IQ_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.MAP_OF_INTERESTS,
//         url: routes['professional_quality_tests/map_of_interests'].url,
//         menuName: routes['professional_quality_tests/map_of_interests'].title,
//         title: 'Карта интересов',
//         name: 'Карта интересов',
//         description: 'Тест на профессиональные качеста',
//         keywords: 'Карта интересов, карта, интересов, профориентация, тест, прохождение, онлайн, результат, интерпретация.',
//         parent: MENU_LIST.PROFESSIONAL_QUALITY_TESTS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.SHUTC,
//         url: routes['tests_on_the_type_of_interpersonal_relationships/shutc'].url,
//         menuName: routes['tests_on_the_type_of_interpersonal_relationships/shutc'].title,
//         title: 'Опросник межличностных отношений В. Шутца (FIRO)',
//         name: 'ОМО В. Шутца (FIRO)',
//         description: 'Тест на тип межличностных отношений',
//         keywords: 'Шутц, FIRO, опросник, межличностных, отношений, тест, онлайн, прохождение, результат, интерпретация.',
//         parent: MENU_LIST.TESTS_ON_THE_TYPE_OF_INTERPERSONAL_RELATIONSHIPS,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.SELF_DEVELOPMENT_TEST,
//         url: routes['complex_testing/self_development_test'].url,
//         menuName: routes['complex_testing/self_development_test'].title,
//         title: 'Тестирование на саморазвитие',
//         name: 'Тестирование на саморазвитие',
//         description: 'Тестирование на саморазвитие',
//         keywords: 'Тестирование на саморазвитие',
//         parent: MENU_LIST.COMPLEX_TESTING,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.SELF_REALIZATION_TEST,
//         url: routes['complex_testing/self_realization_test'].url,
//         menuName: routes['complex_testing/self_realization_test'].title,
//         title: 'Тестирование на самореализацию',
//         name: 'Тестирование на самореализацию',
//         description: 'Тестирование на самореализацию',
//         keywords: 'Тестирование на самореализацию',
//         parent: MENU_LIST.COMPLEX_TESTING,
//         nested: false,
//         visible: true,
//     },
//     {
//         id: MENU_LIST.SITEMAP,
//         url: routes['sitemap'].url,
//         menuName: routes['sitemap'].title,
//         title: 'Карта сайта',
//         name: 'Карта сайта',
//         description: 'Карта сайта',
//         keywords: 'Карта сайта',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     },
//     {
//         id: MENU_LIST.POLITIKA_KONFIDENCIALNOSTI,
//         url: routes['politika_konfidencialnosti'].url,
//         menuName: routes['politika_konfidencialnosti'].title,
//         title: 'Политика конфиденциальности',
//         name: 'Политика конфиденциальности',
//         description: 'Политика конфиденциальности',
//         keywords: 'Политика конфиденциальности',
//         parent: MENU_LIST.NONE,
//         nested: false,
//         visible: false,
//     },
// )

export default mainMenu;
