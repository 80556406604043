import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import style from './index.module.scss';
import {emailValidation} from "../../../Base/helpers/email-validation";
import Loader from "../../../Base/components/Loader";
import {CloseIcon} from "../../../MU/Icons";
import TextInput from "../../../Base/components/Inputs/TextInput";
import Button from "../../../Base/components/Button";
import CheckboxMU from "../../../Base/components/Inputs/Checkbox";
import mainRoutes from "../../../Base/constants/routes";
import {POPUP_AUTHORIZATION_TYPE} from "../../../Popup/store/types";
import {setPopupAuthorization} from "../../../Popup/store/actions";
import {dataDefaultPopupAuthorization} from "../../../Popup/store/constants";
import {registrationRequest} from "../../store/actions";
import {useHistory} from "react-router-dom";


const Registration = (
    {
        setPopupAuthorization,
        popupAuthorization,
        registrationRequest,
        registration,
        isAuth
    }) => {
    const [email, setEmail] = useState({
        value: '',
        isError: false,
        error: ''
    });
    // const [name, setName] = useState('');
    // const [phone, setPhone] = useState('');
    const [isAgree, setAgree] = useState(true);

    const routes = mainRoutes();
    const history = useHistory();

    useEffect(() => {
            const {error} = registration;
            if (error) {
                setEmail({...email, isError: true, error: error});
                return;
            }

            if (isAuth) {
                // history.push(popupAuthorization.url);
            }
        },
        [registration.error, isAuth])


    const newRegistration = () => {
        // Валидация почты
        if (!emailValidation(email.value)) {
            setEmail({...email, isError: true, error: 'Некорректный почтовый адрес'});
            return;
        }

        registrationRequest(email.value);

    };

    return (

        <div className={style['container']}>
            <Loader flag={registration.isLoad}>
                <div className={style['header']}>
                    <div
                        className={style['title']}>{popupAuthorization.name.title ? popupAuthorization.name.title : 'Форма заполнения данных'}</div>
                    <div className={style['close']} title={'Закрыть'} onClick={() => {
                        setPopupAuthorization(dataDefaultPopupAuthorization)
                    }}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={style['content']}>
                    <div className={style['element']}>
                        <TextInput
                            ico={'MailOutlineIcon'}
                            error={email.isError}
                            label={"E-mail*"}
                            value={email.value}
                            onChange={event => {
                                if (registration.isLoad) return;
                                setEmail({...email, value: event.target.value, isError: false, error: ''});
                            }}
                            helperText={email.error}
                        />
                        {/*<div className={style['field']}>*/}
                        {/*    <TextInput*/}
                        {/*        ico={'PersonIcon'}*/}
                        {/*        label={"Имя"}*/}
                        {/*        value={name}*/}
                        {/*        onChange={event => {*/}
                        {/*            if (isLoad) return;*/}
                        {/*            setName(event.target.value);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className={style['field']}>*/}
                        {/*    <TextInput*/}
                        {/*        ico={'PhoneIcon'}*/}
                        {/*        label={"Телефон"}*/}
                        {/*        value={phone}*/}
                        {/*        onChange={event => {*/}
                        {/*            if (isLoad) return;*/}
                        {/*            setPhone(event.target.value);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}


                    </div>
                    <div className={style['element_g']}>
                        <CheckboxMU
                            checked={isAgree}
                            onChange={() => {
                                if (registration.isLoad) return;
                                setAgree(!isAgree)
                            }}
                        />
                        <div className={style['text']}>Я согласен на обработку моих персональных данных в соответствии с
                            <a className={style['href']} href={routes.politika_konfidencialnosti.url}
                               target={'_blank'}>Условиями</a>
                            .
                        </div>
                    </div>
                </div>
                <div className={style['footer']}>
                    <div className={style['button']}>
                        <Button
                            name={popupAuthorization.name.button ? popupAuthorization.name.button : 'Отправить'}
                            active={isAgree && email.value !== '' && !email.error}
                            onClick={() => {
                                newRegistration();
                            }}
                            isEnter={true}
                        />
                    </div>
                    <div className={style['footer_text']}>
                        <div
                            className={style['footer_href']}
                            onClick={() => {
                                setPopupAuthorization({
                                    type: POPUP_AUTHORIZATION_TYPE.AUTHORIZATION,
                                    isOpen: true,
                                    url: popupAuthorization.url
                                })
                            }}
                            title={'Личный кабинет'}
                        >Личный кабинет
                        </div>
                    </div>
                </div>
            </Loader>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        popupAuthorization: state.popup.popupAuthorization,
        registration: state.auth.registration,
        isAuth: state.auth.isAuth,
    }
};

const mapDispatchToProps = {
    setPopupAuthorization, registrationRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);