import React from "react";
import {TextField} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import style from "./index.module.scss";
import {LockIcon, MailOutlineIcon, PersonIcon, PhoneIcon} from "../../../../MU/Icons";


const TextInput = ({children, ...props}) => {
    const {value, ico, error} = props;
    const color = error ? '#ff7f7f' : value === "" ? '#d6d6d6' : '#9cda98';

    let objectIco = null;
    switch (ico) {
        case 'MailOutlineIcon':
            objectIco = MailOutlineIcon;
            break;
        case 'PersonIcon':
            objectIco = PersonIcon;
            break;
        case 'LockIcon':
            objectIco = LockIcon;
            break;
        case 'PhoneIcon':
            objectIco = PhoneIcon;
            break;

    }
    let IconStyle = null;
    if (objectIco) {
        IconStyle = withStyles( ()=> {
            return {
                root: {
                    color: color,
                }
            }
        })(objectIco);
    }

    // Фомируем стили для Input
    const textFieldStyle = makeStyles( ()=> {

        const paddingLeft = ico
            ? value === ""
                ? 30
                : 0
            : 0 ;

        const paddingLeftText = ico
            ? 30
            : 0 ;

        const obj = {
            root: {
                width:'100%',
                '& label': {
                    paddingLeft: paddingLeft,
                    fontSize: 16,
                },
                '& label.Mui-focused': {
                    paddingLeft: 0,
                    color:  color
                },
                '& input': {
                    padding: '6px 0 10px '+paddingLeftText+'px',
                    fontFamily:'PantonRegular',
                    fontSize: 16,
                    color: '#4e4e4e'
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: color,
                },
                '& .MuiInput-underline:before': {
                    borderBottomColor: color,
                },

            },
            menu: {
                height: 400
            }
        };
        return obj;
    });
    const classes = textFieldStyle();

    return (
        <div className={style['container']}>
            {objectIco && IconStyle ? <div className={style['position']}><IconStyle className={error ? style['ico_error'] : style['ico']}/></div> : null}
            <TextField
                className={classes.root}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu
                    }
                }}
                {...props}
            >
                {children}
            </TextField>
        </div>
    );

};

export default TextInput;