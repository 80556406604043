import {PROFESSIONS} from "./constants";
import {IDataProfessions, IElementProfessions} from "./type";

export const getListProfessionsRequest = () => {
    return {
        type: PROFESSIONS.GET_LIST_PROFESSIONS_REQUEST,
    }
};

export const getListProfessionsAnswer = (list: IElementProfessions[]) => {
    return {
        type: PROFESSIONS.GET_LIST_PROFESSIONS_ANSWER,
        payload: list
    }
};

export const getDataProfessionsRequest = (url: string) => {
    return {
        type: PROFESSIONS.GET_DATA_PROFESSIONS_REQUEST,
        payload: url
    }
};

export const getDataProfessionsAnswer = (data: IDataProfessions) => {
    return {
        type: PROFESSIONS.GET_DATA_PROFESSIONS_ANSWER,
        payload: data
    }
};

export const clearDataProfessions = () => {
    return {
        type: PROFESSIONS.CLEAR_DATA_PROFESSIONS,
    }
};
