import React from 'react';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#26a2b2',
    },

})(PlayCircleOutlineIcon);

const PlayCircleOutlineIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default PlayCircleOutlineIconStyle;