import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    },

})(ChevronRightIcon);

const ChevronRightIconStyle = () => {
    return (
        <Style />
    );
};


export default ChevronRightIconStyle;
