import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(HelpIcon);

const HelpIconStyle = ({...props}) => {
    return (
        <Style {...props}/>
    );
};


export default HelpIconStyle;