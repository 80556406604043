import {PAY} from "./constants";
import {IPayDataList, ISendDataNewPay, ISendPayYooKassa, PAY_METHODS} from "./types";

export const getPayDataListRequest = () => {
    return {
        type: PAY.GET_LIST_REQUEST,
    }
}

export const getPayDataListAnswer = (list: IPayDataList) => {
    return {
        type: PAY.GET_LIST_ANSWER,
        payload: list
    }
}

export const getPayDataListError = (error: number) => {
    return {
        type: PAY.GET_LIST_ERROR,
        payload: error
    }
}


export const sendPayYooKassa = (data: ISendPayYooKassa) => {
    return {
        type: PAY.SEND_PAY_YOO_KASSA,
        payload: data
    }
}
