import React from 'react';
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(ArrowRightAltOutlinedIcon);

const ArrowRightAltOutlinedIconStyle = () => {
    return (
        <Style />
    );
};


export default ArrowRightAltOutlinedIconStyle;