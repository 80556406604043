import {mainMenuInterface} from "../../../constants/menu";
import mainRoutes from "../../../constants/routes";
const routes = mainRoutes();

export const isVisibleMenu = (elem: mainMenuInterface, parent: number, admin: boolean, curator: boolean): boolean => {
    let isVisible;

    switch (elem.url) {
        case routes.admin.url:
            isVisible = Number(admin) !== 0 && elem.parent === parent;
            break;
        case routes.curators.url:
            isVisible = Number(admin) !== 0 && Number(curator) !== 0 && elem.parent === parent;
            break;
        default:
            isVisible = elem.parent === parent;
    }
    return isVisible;
}