//@ts-nocheck
import {call, put, select, takeLatest} from "redux-saga/effects";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {DOWNLOAD_CONSTANTS} from "./constants";
import {
    downloadGetDataCuratorPackageAnswer, downloadGetDataCuratorPackageError,
    downloadGetDataPackageAnswer,
    downloadGetDataPackageError,
    downloadGetDataTestAnswer,
    downloadGetDataTestError
} from "./actions";
import {BaseState} from "../../Base/rootReducer";

function* getDataTestRequestSaga({payload}: any) {
    try {
        const request = yield call(
            getResource,
            '/getDataResultTest/',
            METHOD_REQUEST.POST,
            {},
            {data: {id: payload}}
        );

        if (request.success) {
            const {data} = request.success;
            yield put(downloadGetDataTestAnswer(data))
        }

        if (request.error) {
            yield put(downloadGetDataTestError(request.error.code))
        }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataPackageRequestSaga({payload}: any) {
    try {
        const request = yield call(
            getResource,
            '/getDataPackage/',
            METHOD_REQUEST.POST,
            {},
            {data: {hash:payload}}
        );

        if (request.success) {
            const {data} = request.success;
            yield put(downloadGetDataPackageAnswer(data))
        }

        if (request.error) {
            yield put(downloadGetDataPackageError(request.error.code))
        }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataCuratorPackageRequestSaga({payload}: any) {
    try {
        const token = yield select((state: BaseState) => state.auth.token);

        const request = yield call(
            getResource,
            '/getDataCuratorPackageDownload/',
            METHOD_REQUEST.POST,
            {"Authorization": "Token " + token},
            {data: {hash:payload}}
        );

        if (request.success) {
            const {data} = request.success;
            yield put(downloadGetDataCuratorPackageAnswer(data))
        }

        if (request.error) {
            yield put(downloadGetDataCuratorPackageError(request.error.code))
        }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}


export default function* watchDownload() {
    yield takeLatest(DOWNLOAD_CONSTANTS.TEST_DATA_REQUEST, getDataTestRequestSaga);
    yield takeLatest(DOWNLOAD_CONSTANTS.PACKAGE_DATA_REQUEST, getDataPackageRequestSaga);
    yield takeLatest(DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_REQUEST, getDataCuratorPackageRequestSaga);
}