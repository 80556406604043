import {DOWNLOAD_CONSTANTS} from "./constants";
import {IDataRunTest} from "../../Test/store/type";
import {IDataRunPackage} from "../../Package/store/type";

export interface IStateDownload {
    dataTest: IDataRunTest,
    error: number,
    isLoad: boolean,
    dataPackage: IDataRunPackage
}



const initialState: IStateDownload = {
    // @ts-ignore
    dataTest: {},
    // @ts-ignore
    dataPackage: {},
    isLoad: true,
    error: 0,
}

export const downloadReducer = (store = initialState, action: { type: DOWNLOAD_CONSTANTS, payload?: any }) => {
    switch (action.type) {
        case DOWNLOAD_CONSTANTS.TEST_DATA_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case DOWNLOAD_CONSTANTS.TEST_DATA_ANSWER:
            return {
                ...store,
                dataTest: action.payload,
            };
        case DOWNLOAD_CONSTANTS.TEST_DATA_ERROR:
            return {
                ...store,
                error: action.payload,
            };
        case DOWNLOAD_CONSTANTS.PACKAGE_DATA_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case DOWNLOAD_CONSTANTS.PACKAGE_DATA_ANSWER:
            return {
                ...store,
                dataPackage: action.payload,
            };
        case DOWNLOAD_CONSTANTS.PACKAGE_DATA_ERROR:
            return {
                ...store,
                error: action.payload,
            };
        case DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_ANSWER:
            return {
                ...store,
                dataPackage: action.payload,
            };
        case DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_ERROR:
            return {
                ...store,
                error: action.payload,
            };

    }
    return store;
}
