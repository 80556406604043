import React from "react";
import {connect} from "react-redux";
import {Popup} from "../index";
import style from "./index.module.scss";
import {CloseIcon} from "../../../MU/Icons";
import {BUTTON_FORM} from "../../../Base/components/Button/type";
import Button from "../../../Base/components/Button";
import {setPopup} from "../../store/actions";

const PopupSystem = ({popup, setPopup}) => {
    const {title, description, type, isOpen, html, onClick=()=>{}} = popup;

    const save = ()=>{
        onClick();
        setPopup({title,description,type,isOpen:false, html:false})
    };

    return (
        <Popup open={isOpen} onClose={()=>{save()}}>
            <div className={style['container']}>
                <div className={style['header']}>
                    <div className={style['title']}>{title}</div>
                    <div className={style['close']} title={'Закрыть'} onClick={()=>{save()}}>
                        <CloseIcon />
                    </div>
                </div>

                {html
                    ? <div className={style['content']} dangerouslySetInnerHTML={{__html:description}} />
                    : <div className={style['content']}>{description}</div>
                }
                <div className={style['footer']}>
                    <Button
                        form={BUTTON_FORM.SQUARE}
                        name={'OK'}
                        onClick={()=>{save()}}
                    />
                </div>
            </div>
        </Popup>
    );
};

const mapStateToProps = (state) => {
    return {popup: state.popup.popup};
};

const mapDispatchToProps = {
    setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupSystem);