import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#dcdcdc',//f7f7f7 //dcdcdc
    },

})(AssignmentIcon);

const AssignmentIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default AssignmentIconStyle;