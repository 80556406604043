import React from 'react';
import AlarmIcon from '@material-ui/icons/Alarm';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#4e4e4e',
    },

})(AlarmIcon);

const AlarmIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default AlarmIconStyle;