
export enum METHOD_REQUEST {
    POST = 'post',
    GET = 'get'
}

const BASE = `/api/v1`;

export const getResource = async (url :string, method: METHOD_REQUEST, headers = {}, body= {}): Promise<any> => {

    let obj = {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    };
    if (method === METHOD_REQUEST.GET) {
        // @ts-ignore
        obj = {
            method: method,
            headers: headers,
        };
    }
    const res = await fetch(`${BASE}${url}`, obj);

    if (!res.ok) {
        throw new Error(`Ошибка ${url} , recerved ${res.status}`);
    }
    const data = await res.json();
    return data;
};