import {call, put, select, takeLatest} from "redux-saga/effects";
import {AUTH, CHANGE_PASSWORD, LOGOUT, REGISTRATION} from "./constants";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {setPopup, setPopupAuthorization} from "../../Popup/store/actions";
import {
    authAnswer,
    authClearError,
    changePassAnswer,
    changePassClear,
    changePassError,
    checkHashAnswer,
    logoutAnswer,
    newPassAnswer,
    registrationClear,
    registrationError
} from "./actions";
import {dataDefaultPopup, dataDefaultPopupAuthorization, POPUP} from "../../Popup/store/constants";
import {clearLocalStorage, setLocalStorage} from "../../Base/helpers/local-storage";
import {LOCAL_STORAGE_CODE} from "../../Base/constants/local-storage";
import {BaseState} from "../../Base/rootReducer";
import {getDataUserAnswer, getDataUserRequest} from "../../User/store/actions";
import {defaultDataUser} from "../../User/store/constants";
import {CHECK_HASH, NEW_PASS} from "./types";
import {sendMessageTelegram} from "../../Telegram/store/actions";
import {MESSAGES_TELEGRAM} from "../../Telegram/store/constants";
import { IAuthState } from "./store";


export function* authRequest({payload}: any) {
    const {login, password} = payload;
    try {
        // @ts-ignore
        const request = yield call(getResource, '/auth/', METHOD_REQUEST.POST, {}, {username: login, password});

        let isAuth = false;
        let token = '';
        let error = '';
        if (request.success) {
            isAuth = true;
            token = request.success.token;

        }

        setLocalStorage(LOCAL_STORAGE_CODE.AUTH, {isAuth, token})

        if (request.error) {
            error = request.error.error_message;
            console.warn(error);
        }

        yield put(authAnswer({token, isAuth, error: error, isLoad: false}));

        if (isAuth) yield put(setPopupAuthorization(dataDefaultPopupAuthorization));


    } catch (error) {
        yield put(setPopup(dataDefaultPopup))
        yield put(authClearError())
    }

}

export function* authClearErrorSaga() {

    const auth: IAuthState = yield select((state: BaseState) => state.auth);

    // Чистим ошибку и загрузку попапа авторизации
    if (auth.error || auth.isLoad) {
        yield put(authClearError())
    }
    // Чистим ошибку и загрузку попапа регистрации
    if (auth.registration.error || auth.registration.isLoad) {
        yield put(registrationClear())
    }

    // Чистим ошибку и загрузку попапа смены пароля
    if (auth.changePass.error || auth.changePass.isLoad || auth.changePass.sendMail) {
        yield put(changePassClear())
    }

}

export function* checkTokenSaga() {
    // Получить токен
    const token: string = yield select((state: BaseState) => state.auth.token);
    if (token) {
        try {
            // @ts-ignore
            const request = yield call(getResource, '/checkToken/', METHOD_REQUEST.POST, {}, {data: {token}});
            if (request.success && request.success.id) {
                // Получение данных пользователя
                yield put(getDataUserRequest());
                yield put(authAnswer({token: token, isAuth: true}))
            } else {
                clearLocalStorage();
                yield put(authAnswer({token: '', isAuth: false}))
            }
        } catch (error) {
            yield put(authAnswer({token: '', isAuth: false}))
        }
    } else {
        // Если токена нет, то на всякий случай чистим авторизацию
        yield put(authAnswer({isAuth: false}))

    }

}

function* logoutSaga() {
    const token: string = yield select((state: BaseState) => state.auth.token);
    // @ts-ignore
    const request = yield call(getResource, '/logout/', METHOD_REQUEST.POST, {"Authorization": "Token " + token}, {});

    if (request.success) {
        yield put(authAnswer({token: '', isAuth: false}));
        yield put(getDataUserAnswer(defaultDataUser));
        clearLocalStorage();
        yield put(logoutAnswer(true));
    }
}

function* registrationRequestSaga({payload}: any) {
    try {
        const login = payload;
        // @ts-ignore
        const request = yield call(getResource, '/registration/', METHOD_REQUEST.POST, {}, {username: login});
        if (request.success && request.success.token) {
            // Регистрация удалась
            yield put(authAnswer({token: request.success.token, isAuth: true}))
            setLocalStorage(LOCAL_STORAGE_CODE.AUTH, {isAuth: true, token: request.success.token})
            yield put(setPopupAuthorization(dataDefaultPopupAuthorization));

            // Отправляем уведомление в телеграмм бот
            yield put(sendMessageTelegram(MESSAGES_TELEGRAM.NEW_REGISTRATION + login));

        }

        if (request.error) {
            yield put(registrationError(request.error.error_message))
        }
    } catch (e) {
        // Обработать ошибку
    }
}

function* changePassRequestSaga({payload}: any) {
    const login = payload;
    try {
        // @ts-ignore
        const request = yield call(getResource, '/change/', METHOD_REQUEST.POST, {}, {username: login});
        if (request.success) {
            yield put(changePassAnswer())
        }

        if (request.error) {
            yield put(changePassError(request.error.error_message))
        }
    } catch (e) {
        // Обработать ошибку
    }
}

function* checkHashRequestSaga({payload}: any) {
    const hash = payload || '';

    try {
        // @ts-ignore
        const request = yield call(getResource, '/checkHash/', METHOD_REQUEST.POST, {}, {data: {hash: hash}});
        if (request.success && request.success.id) {
            yield put(checkHashAnswer({userId: request.success.id, checkHash: CHECK_HASH.SUCCESS}));
        } else {
            yield put(checkHashAnswer({userId: 0, checkHash: CHECK_HASH.ERROR}));
        }

    } catch (e) {
        // Обработать ошибку
    }

}

function* newPassRequestSaga ({payload}: any) {
    const {userId, pass} = payload;
    try {
        // @ts-ignore
        const request = yield call(getResource, '/changeSave/', METHOD_REQUEST.POST, {}, {data: {userId, password:pass}});
        if (request.success) {
            yield put(newPassAnswer(NEW_PASS.SUCCESS));
        } else {
            yield put(newPassAnswer(NEW_PASS.ERROR));
        }

    } catch (e) {
        // Обработать ошибку
        yield put(newPassAnswer(NEW_PASS.ERROR));
    }
}

export default function* watchAuth() {
    yield takeLatest(AUTH.AUTH_REQUEST, authRequest);
    yield takeLatest(POPUP.SET_POPUP_AUTHORIZATION, authClearErrorSaga);
    yield takeLatest(AUTH.CHECK_TOKEN, checkTokenSaga);
    yield takeLatest(LOGOUT.REQUEST, logoutSaga);
    yield takeLatest(REGISTRATION.REQUEST, registrationRequestSaga);
    yield takeLatest(CHANGE_PASSWORD.REQUEST, changePassRequestSaga);
    yield takeLatest(CHANGE_PASSWORD.CHECK_HASH_REQUEST, checkHashRequestSaga);
    yield takeLatest(CHANGE_PASSWORD.NEW_PASS_REQUEST, newPassRequestSaga);
}