import React from 'react';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(ArrowDropUpOutlinedIcon);

const ArrowDropUpOutlinedIconStyle = ({children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default ArrowDropUpOutlinedIconStyle;