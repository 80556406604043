import {IDataExam, IExamListData, ITestedData, ITestedListData} from "./type";
import {CURATORS, DEFAULT_DATA_TESTED_2} from "./constants";

export interface IStateCurators {
    listTested: ITestedListData[],
    listExams: IExamListData[],
    dataExam: IDataExam,
    edit: ITestedData,
    saveId: number;
    isLoad: boolean;
    error: number;
}


const initialState: IStateCurators = {
    listTested: [],
    listExams: [],
    edit: DEFAULT_DATA_TESTED_2,
    saveId: 0,
    isLoad: false,
    // @ts-ignore
    dataExam: {
        name: "",
        post: "",
        date_create: 0,
        packages: []
    },
    error: 0
}

export const curatorsReducer = (store = initialState, action: { type: CURATORS, payload?: any }) => {
    const payload = action.payload;

    switch (action.type) {
        case CURATORS.GET_LIST_TESTED_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case CURATORS.GET_LIST_TESTED_ANSWER:
            return {
                ...store,
                listTested: payload,
                isLoad: false
            }
        case CURATORS.GET_DATA_TESTED_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case CURATORS.GET_DATA_TESTED_ANSWER:
            return {
                ...store,
                edit: payload,
                isLoad: false
            }
        case CURATORS.SAVE_DATA_TESTED_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case CURATORS.SAVE_DATA_TESTED_ANSWER:
            return {
                ...store,
                saveId: payload,
                isLoad: false
            }
        case CURATORS.CLEAR_SAVE_ID:
            return {
                ...store,
                saveId: 0
            }
        case CURATORS.CLEAR_ERROR:
            return {
                ...store,
                error: 0
            }
        case CURATORS.SET_LOAD:
            return {
                ...store,
                isLoad: payload
            }
        case CURATORS.CREATE_EXAM_REQUEST:
            return {
                ...store,
                isLoad: true,
            };
        case CURATORS.CREATE_EXAM_ANSWER:
            return {
                ...store,
                saveId: payload,
                isLoad: false
            }
        case CURATORS.GET_LIST_EXAMS_REQUEST:
            return {
                ...store,
                isLoad: true
            }
        case CURATORS.GET_LIST_EXAMS_ANSWER:
            return {
                ...store,
                isLoad: false,
                listExams: payload
            }
        case CURATORS.GET_DATA_EXAM_REQUEST:
            return {
                ...store,
                isLoad: true
            }
        case CURATORS.GET_DATA_EXAM_ANSWER:
            return {
                ...store,
                isLoad: false,
                dataExam: payload
            }
        case CURATORS.GET_DATA_EXAM_ERROR:
            return {
                ...store,
                error: payload
            }
    }

    return store;
}