import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    },

})(MenuIcon);

const MenuIconStyle = ( {children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default MenuIconStyle;