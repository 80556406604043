
export interface IDataAuthRequest {
    login: string,
    password: string
}

export interface IDataAuthAnswer {
    token?: string,
    isAuth?: boolean,
    error?: string
    isLoad?: boolean
}

export interface IRegistration {
    isLoad: boolean,
    error: string
}

export enum CHECK_HASH {
    NOT_REQUEST = 0,
    SUCCESS = 1,
    ERROR = 2
}

export enum NEW_PASS {
    NOT_REQUEST = 0,
    SUCCESS = 1,
    ERROR = 2
}