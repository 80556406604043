import {POPUP_AUTHORIZATION_TYPE, ISetPopupAuthorization, ISetPopup, POPUP_TYPE} from "./types";
import mainRoutes from "../../Base/constants/routes";

const routes = mainRoutes();

export enum POPUP {
    SET_POPUP_AUTHORIZATION = "SET_POPUP_AUTHORIZATION",
    SET_POPUP = "SET_POPUP",
}

export const dataDefaultPopupAuthorization:ISetPopupAuthorization = {
    type: POPUP_AUTHORIZATION_TYPE.NONE,
    isOpen: false,
    url: routes.my_testing.url,
    name: {title: '', button: ''}
}

export const dataDefaultPopup: ISetPopup = {
    title: 'Ошибка сервера!',
    description: 'Сервер не доступен. Пожалуйста, перезагрузите страницу.',
    isOpen: false,
    type: POPUP_TYPE.ALERT,
    html: false,
    onClick: ()=>{}
}


