import {CURATORS} from "./constants";
import {IDataCreateExam, IDataExam, IExamListData, ISaveTestedData, ITestedData, ITestedListData} from "./type";

export const getListTestedRequest = () => {
    return {
        type: CURATORS.GET_LIST_TESTED_REQUEST,
    }
};

export const getListTestedAnswer = (data: ITestedListData) => {
    return {
        type: CURATORS.GET_LIST_TESTED_ANSWER,
        payload: data
    }
}

export const getDataTestedRequest = (data: number) => {
    return {
        type: CURATORS.GET_DATA_TESTED_REQUEST,
        payload: data
    }
};

export const getDataTestedAnswer = (data: ITestedData) => {
    return {
        type: CURATORS.GET_DATA_TESTED_ANSWER,
        payload: data
    }
}

export const saveDataTestedRequest = (data: ISaveTestedData) => {
    return {
        type: CURATORS.SAVE_DATA_TESTED_REQUEST,
        payload: data
    }
};

export const saveDataTestedAnswer = (data: number) => {
    return {
        type: CURATORS.SAVE_DATA_TESTED_ANSWER,
        payload: data
    }
};

export const deleteTestedRequest = (data: number) => {
    return {
        type: CURATORS.DELETE_TESTED_REQUEST,
        payload: data
    }
};

export const clearSaveId = () => {
    return {
        type: CURATORS.CLEAR_SAVE_ID,
    }
};

export const setLoadCurators = (data: boolean) => {
    return {
        type: CURATORS.CLEAR_SAVE_ID,
        payload: data
    }
};

export const createExamRequest = (data: IDataCreateExam) => {
    return {
        type: CURATORS.CREATE_EXAM_REQUEST,
        payload: data
    }
}

export const createExamAnswer= (id: number) => {
    return {
        type: CURATORS.CREATE_EXAM_ANSWER,
        payload: id
    }
}

export const getListExamsRequest = () => {
    return {
        type: CURATORS.GET_LIST_EXAMS_REQUEST,
    }
};

export const getListExamsAnswer = (data: IExamListData) => {
    return {
        type: CURATORS.GET_LIST_EXAMS_ANSWER,
        payload: data
    }
}

export const deleteExamsRequest = (id: number) => {
    return {
        type: CURATORS.DELETE_EXAM_REQUEST,
        payload: id
    }
}

export const getDataExamRequest = (data: number) => {
    return {
        type: CURATORS.GET_DATA_EXAM_REQUEST,
        payload: data
    }
};

export const getDataExamAnswer = (data: IDataExam) => {
    return {
        type: CURATORS.GET_DATA_EXAM_ANSWER,
        payload: data
    }
}

export const getDataExamError = (error: number) => {
    return {
        type: CURATORS.GET_DATA_EXAM_ERROR,
        payload: error
    }
}

export const clearError = () => {
    return {
        type: CURATORS.CLEAR_ERROR,
    }
}

