export enum PACKAGE {
    PORTRAIT_PERSON = 'PORTRAIT_PERSON'
}

export enum STATUS_PACKAGE {
    NEW = 0,
    RUN = 1,
    FINISH = 2,
    ON_INTERPRETATION = 3,
    FINISH_INTERPRETATION = 4,
}