

const mainRoutes = () => {
    const url = '/';
    return ({
        main: {
            url: `${url}`,
            title: 'Главная'
        },
        self_realization: {
            url: `${url}professional_online`,
            title: 'Ваша самореализация'
        }, //self_realization
        self_development: {
            url: `${url}self_development`,
            title: 'Ваше саморазвитие'
        }, //self_development
        online_course: {
            url: `${url}online_course`,
            title: 'Онлайн-курс'
        },
        change: {
            url: `${url}change`,
            title: 'Восстановаление пароля'
        },
        complex_testing: {
            url: `${url}complex_testing`,
            title: 'Комплексное тестирование'
        },
        catalogue_of_professions: {
            url: `${url}catalogue_of_professions`,
            title: 'Каталог профессий'
        },
        articles: {
            url: `${url}articles`,
            title: 'Статьи'
        },
        '404': {
            url: `${url}404`,
            title: 'Страница 404'
        },
        sitemap: {
            url: `${url}sitemap`,
            title: 'Карта сайта'
        },
        'complex_personality_tests': {
            url: `${url}complex_personality_tests`,
            title: 'Многофакторные личностные тесты'
        },
        'one_factor_personality_tests': {
            url: `${url}one_factor_personality_tests`,
            title: 'Однофакторные личностные тесты'
        },
        'projective_tests': {
            url: `${url}projective_tests`,
            title: 'Проективные тесты'
        },
        'the_iq_tests': {
            url: `${url}the_iq_tests`,
            title: 'Тесты на интеллект'
        },
        'professional_quality_tests': {
            url: `${url}professional_quality_tests`,
            title: 'Тесты на профессиональные качества'
        },
        'tests_on_the_type_of_interpersonal_relationships': {
            url: `${url}tests_on_the_type_of_interpersonal_relationships`,
            title: 'Тесты на тип межличностных отношений'
        },
        'complex_personality_tests/akcent': {
            url: `${url}complex_personality_tests/akcent`,
            title: 'Акцент 2-90'
        },
        'complex_personality_tests/kettel': {
            url: `${url}complex_personality_tests/kettel`,
            title: 'Опросник Р. Кеттела 16PF'
        },
        'complex_personality_tests/mmil': {
            url: `${url}complex_personality_tests/mmil`,
            title: 'ММИЛ (MMPI)'
        },
        'complex_personality_tests/keirsey': {
            url: `${url}complex_personality_tests/keirsey`,
            title: 'Опросник Кейрси'
        },
        'one_factor_personality_tests/aizenk': {
            url: `${url}one_factor_personality_tests/aizenk`,
            title: 'Тест Г. Айзенка (EPI)'
        },
        'one_factor_personality_tests/emotional_intelligence': {
            url: `${url}one_factor_personality_tests/emotional_intelligence`,
            title: 'Тест на уровень развития эмоционального интеллекта'
        },
        'one_factor_personality_tests/bdhi': {
            url: `${url}one_factor_personality_tests/bdhi`,
            title: 'Опросник агрессивности Басса-Дарки'
        },
        'projective_tests/lyusher': {
            url: `${url}projective_tests/lyusher`,
            title: 'Цветовой тест М.Люшера'
        },
        'projective_tests/sondi': {
            url: `${url}projective_tests/sondi`,
            title: 'Метод портретных выборов Л. Сонди'
        },
        'the_iq_tests/kot': {
            url: `${url}the_iq_tests/kot`,
            title: 'К.О.Т.'
        },
        'professional_quality_tests/map_of_interests': {
            url: `${url}professional_quality_tests/map_of_interests`,
            title: 'Карта интересов'
        },
        'professional_quality_tests/gerchikov': {
            url: `${url}professional_quality_tests/gerchikov`,
            title: 'Тест оценки мотивации Герчикова'
        },
        'tests_on_the_type_of_interpersonal_relationships/shutc': {
            url: `${url}tests_on_the_type_of_interpersonal_relationships/shutc`,
            title: 'ОМО В. Шутца (FIRO)'
        },
        'complex_testing/self_development_test': {
            url: `${url}complex_testing/test_self_development`,
            title: 'Тестирование на саморазвитие'
        },
        'complex_testing/self_realization_test': {
            url: `${url}complex_testing/test_self_realization`,
            title: 'Тестирование на самореализацию'
        },
        'politika_konfidencialnosti': {
            url: `${url}politika_konfidencialnosti`,
            title: 'Политика конфиденциальности'
        },
        admin: {
            url: `${url}admin`,
            title: 'Админка'
        },
        account: {
            url: `${url}account`,
            title: 'Аккаунт'
        },
        my_testing: {
            url: `${url}my_testing`,
            title: 'Моё тестирование'
        },
        my_online_course: {
            url: `${url}my_online_course`,
            title: 'Мой онлайн-курс'
        },
        download_test: {
            url: `${url}download_test`,
            title: 'Скачивание результата теста'
        },
        download_package: {
            url: `${url}download_package`,
            title: 'Скачивание результата тестирования'
        },
        download_curator_package: {
            url: `${url}download_curator_package`,
            title: 'Скачивание результата тестирования'
        },
        contract_offer: {
            url: `${url}contract_offer`,
            title: 'Договор-оферта'
        },
        curators: {
            url: `${url}curators`,
            title: 'Куратор'
        },
        tested: {
            url: `${url}curators/tested`,
            title: 'Тестируемые'
        },
        exams: {
            url: `${url}curators/exams`,
            title: 'Обследования'
        },
        curators_testing: {
            url: `${url}curators_testing`,
            title: 'Тестирование'
        },
        curators_telegram: {
            url: `${url}curators_telegram`,
            title: 'Уведомления'
        },
        package: {
            url: `${url}package`,
            title: 'Психологический портрет вашей личности'
        }

    });
}

export default mainRoutes;
