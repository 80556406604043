import {USER_GENDER, UserID} from "../../User/store/types";

export enum TESTS {
    KETTEL = 'kettel',
    SONDI = 'sondi',
    SHUTC = 'shutc',
    MMIL = 'mmil',
    MAP_OF_INTERESTS = 'map_of_interests',
    MAP_OF_INTERESTS_NEW = 'map_of_interests_new',
    LYUSHER = 'lyusher',
    KOT = 'kot',
    EMOTIONAL_INTELLIGENCE = 'emotional_intelligence',
    DIAGNOSTIKA_KREATIVNOSTI_TORRENSA = 'diagnostika_kreativnosti_torrensa',
    AKCENT = 'akcent',
    AIZENK = 'aizenk',
    KEIRSEY = 'keirsey',
    SELF_ASSESSMENT = 'self_assessment',
    BDHI = 'bdhi',
    GERCHIKOV = 'gerchikov',
}

export enum ACTIVE_TEST {
    ACTIVE = 'Y',
    NO_ACTIVE = 'N',
}

export enum TESTS_FORM {
    A = 'a',
    B = 'b'
}

export interface IDataTest {
    name: string,
    section: string,
    descriptionCategory: string,
    url: string,
    interpretation: string,
    is_time: boolean,
    time: number,
    always: boolean,
    gender: boolean,
    age: boolean,
    forms: TESTS_FORM[],
    rulePassing: string,
}

export enum STATUS_TEST {
    START = 'start',
    RUN = 'run',
    FINISH = 'finish'
}

export enum TYPE_QUESTION {
    SOLO = 'S',
    MULTI = 'M',
    INPUT = 'T',
    DOUBLE = 'D',
}

export interface IQuestion {
    code: TESTS,
    description: string | null,
    forms: TESTS_FORM,
    id: number,
    img: string | null,
    name: string,
    sort: number,
    type: TYPE_QUESTION
}

export interface IAnswer {
    code: TESTS,
    number_question: number,
    forms: TESTS_FORM,
    id: number,
    img: string | null,
    name: string,
    sort: number,
    value: number
}

export interface IDataRunTest {
    active: ACTIVE_TEST,
    age: number,
    code: TESTS,
    finished: ACTIVE_TEST,
    forms: TESTS_FORM,
    gender: USER_GENDER,
    hash: string,
    id:number,
    package_result_id: number | null,
    result: [],
    sort: number,
    status: ACTIVE_TEST,
    time_limit: number,
    user: UserID,
    value: {q: number, a: string}[],
    date_create: number,
    date_finish: number,
    date_start: number | null,
    data_users: string | null, //Todo не актуальное поле, нужно избавиться
    questions: IQuestion[],
    answers: IAnswer[],
    missed_questions: number[],
    count_questions: number,
    id_active_question: number,
    variability_sort: number[],
}

export enum TYPE_SONDI {
    PLUS = '+',
    MINUS = '-'
}