import {DOWNLOAD_CONSTANTS} from "./constants";
import {IDataRunTest} from "../../Test/store/type";
import {IDataRunPackage} from "../../Package/store/type";

export const downloadGetDataTestRequest = (id: number) => {
    return {
        type: DOWNLOAD_CONSTANTS.TEST_DATA_REQUEST,
        payload: id
    }
};

export const downloadGetDataTestAnswer = (data: IDataRunTest) => {
    return {
        type: DOWNLOAD_CONSTANTS.TEST_DATA_ANSWER,
        payload: data
    }
};

export const downloadGetDataTestError = (error: number) => {
    return {
        type: DOWNLOAD_CONSTANTS.TEST_DATA_ERROR,
        payload: error
    }
};

export const downloadGetDataPackageRequest = (hash: string) => {
    return {
        type: DOWNLOAD_CONSTANTS.PACKAGE_DATA_REQUEST,
        payload: hash
    }
};

export const downloadGetDataPackageAnswer = (data: IDataRunPackage) => {
    return {
        type: DOWNLOAD_CONSTANTS.PACKAGE_DATA_ANSWER,
        payload: data
    }
};

export const downloadGetDataPackageError = (error: number) => {
    return {
        type: DOWNLOAD_CONSTANTS.PACKAGE_DATA_ERROR,
        payload: error
    }
};

export const downloadGetDataCuratorPackageRequest = (hash: string) => {
    return {
        type: DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_REQUEST,
        payload: hash
    }
};

export const downloadGetDataCuratorPackageAnswer = (data: IDataRunPackage) => {
    return {
        type: DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_ANSWER,
        payload: data
    }
};

export const downloadGetDataCuratorPackageError = (error: number) => {
    return {
        type: DOWNLOAD_CONSTANTS.CURATOR_PACKAGE_DATA_ERROR,
        payload: error
    }
};