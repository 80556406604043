import {ITestedData} from "./type";
import {USER_GENDER} from "../../User/store/types";

export enum CURATORS {
    GET_LIST_TESTED_REQUEST = "CURATORS/GET_LIST_TESTED_REQUEST",
    GET_LIST_TESTED_ANSWER = "CURATORS/GET_LIST_TESTED_ANSWER",
    GET_LIST_TESTED_ERROR = "CURATORS/GET_LIST_TESTED_ERROR",

    GET_DATA_TESTED_REQUEST = "CURATORS/GET_DATA_TESTED_REQUEST",
    GET_DATA_TESTED_ANSWER = "CURATORS/GET_DATA_TESTED_ANSWER",

    SAVE_DATA_TESTED_REQUEST = "CURATORS/SAVE_DATA_TESTED_REQUEST",
    SAVE_DATA_TESTED_ANSWER = "CURATORS/SAVE_DATA_TESTED_ANSWER",
    DELETE_TESTED_REQUEST = "CURATORS/DELETE_TESTED_ANSWER",
    CLEAR_SAVE_ID = "CURATORS/CLEAR_IS_SAVE",

    SET_LOAD = "CURATORS/SET_LOAD",
    CLEAR_ERROR = "CURATORS/CLEAR_ERROR",

    CREATE_EXAM_REQUEST = "CURATORS/CREATE_EXAM_REQUEST",
    CREATE_EXAM_ANSWER = "CURATORS/CREATE_EXAM_ANSWER",

    GET_LIST_EXAMS_REQUEST = "CURATORS/GET_LIST_EXAMS_REQUEST",
    GET_LIST_EXAMS_ANSWER = "CURATORS/GET_LIST_EXAMS_ANSWER",
    DELETE_EXAM_REQUEST = "CURATORS/DELETE_EXAM_REQUEST",

    GET_DATA_EXAM_REQUEST = "CURATORS/GET_DATA_EXAM_REQUEST",
    GET_DATA_EXAM_ANSWER = "CURATORS/GET_DATA_EXAM_ANSWER",
    GET_DATA_EXAM_ERROR = "CURATORS/GET_DATA_EXAM_ERROR",

    }

export const DEFAULT_DATA_TESTED: ITestedData = {
    id: 0,
    curator: 0,
    gender: USER_GENDER.M,
    date_create: 0,
    birthday: Date.now(),
    group: 0,
    name: ''
}

export const DEFAULT_DATA_TESTED_2: ITestedData = {
    id: 0,
    curator: 0,
    gender: USER_GENDER.M,
    date_create: 0,
    birthday: -1,
    group: 0,
    name: ''
}