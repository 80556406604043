import React from 'react';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#75d51c',
    },

})(FiberNewIcon);

const FiberNewIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default FiberNewIconStyle;