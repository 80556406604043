import React from "react";
import style from './index.module.scss'
import Loader from "../Loader";

const MainLoader = () => {

    return <div className={style.container}>
        <Loader flag={true}>
        </Loader>
    </div>;
}

export default MainLoader;