import React, {Fragment, useState} from "react";
import style from "./index.module.scss";
import {Drawer} from "@material-ui/core";
import cx from "classnames";
import mainMenu, {mainMenuInterface} from "../../../constants/menu";
import {Link, useHistory} from "react-router-dom";
import {ArrowDropDownOutlinedIcon, ArrowDropUpOutlinedIcon, MenuIcon} from "../../../../MU/Icons";
import {BaseState} from "../../../rootReducer";
import {connect} from "react-redux";
import mainRoutes from "../../../constants/routes";
import {isVisibleMenu} from "../helpers";

interface IMainMenuMobile {
    isAuth?: boolean,
    admin?: number,
    curator?: number,
}


const MainMenuMobile = (
    {
        isAuth,
        admin,
        curator,
    }: IMainMenuMobile) => {

    const [open, setOpen] = useState(false);
    const menuList: mainMenuInterface[] = mainMenu.filter((menu) => menu.visible);
    const [openMenu, setOpenMenu] = useState<number[]>([]);
    const history = useHistory();
    const routes = mainRoutes();

    const arrayMain = () => {
        let array: number[] = [];
        menuList.forEach(elem => {
            if (history.location.pathname.indexOf(elem.url) !== -1) {
                array.push(elem.id);
                if (elem.parent) {
                    menuList.forEach(elem2 => {
                        if (elem2.id === elem.parent) {
                            array.push(elem2.id);
                        }
                    })
                }

            }
        });
        return array;
    }

    const menuActiveUrl: number[] = arrayMain();

    return (
        <Fragment>
            <div className={style.button_menu} title={'Меню'} onClick={() => {
                setOpen(true)
            }}>
                {
                    // @ts-ignore
                    <MenuIcon fontSize='large' className={style.ico}/>
                }
                <div className={style.text}>Меню</div>
            </div>
            <Drawer
                open={open}
                anchor={"top"}
                onClose={() => {
                    setOpen(false)
                }}
            >
                {
                    menuList.filter(elem => {
                        const admin = !(!isAuth && elem.isAuth); // Если я не авторизировался,
                        // то админские пункты меню не нужно показывать
                        return !elem.parent && elem.visible && admin;
                    }).map((elem) => {
                        if (!elem.nested)
                            return (
                                <Link
                                    key={elem.id}
                                    to={elem.url}
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                    className={cx(
                                        style.element,
                                        {
                                            [style.active]: menuActiveUrl.indexOf(elem.id) !== -1
                                        }
                                    )}
                                >{elem.menuName}</Link>
                            );

                        return (
                            <Fragment key={elem.id}>
                                <div
                                    className={cx(
                                        style.element,
                                        {
                                            [style.active]: menuActiveUrl.indexOf(elem.id) !== -1
                                        }
                                    )}
                                    onClick={() => {
                                        const newMenu = [...openMenu];
                                        const index = newMenu.indexOf(elem.id);
                                        if (index === -1) {
                                            newMenu.push(elem.id);
                                        }
                                        if (index !== -1) {
                                            newMenu.splice(index, 1);
                                        }
                                        setOpenMenu(newMenu);
                                    }}
                                >
                                    {elem.menuName}
                                    {elem.nested &&
                                    openMenu.indexOf(elem.id) !== -1
                                        // @ts-ignore
                                        ? <ArrowDropUpOutlinedIcon fontSize='large'/>
                                        // @ts-ignore
                                        : <ArrowDropDownOutlinedIcon fontSize='large'/>
                                    }
                                </div>
                                {
                                    openMenu.indexOf(elem.id) !== -1 &&
                                    menuList.filter(el => isVisibleMenu(el, elem.id, !!admin, !!curator))
                                        .map((el) => {
                                            return (
                                                <Link
                                                    key={el.id}
                                                    onClick={() => {
                                                        setOpen(false)
                                                    }}
                                                    to={el.url}
                                                    className={cx(
                                                        style.sub_element,
                                                        {
                                                            [style.active]: menuActiveUrl.indexOf(el.id) !== -1
                                                        }
                                                    )}
                                                >
                                                    {el.menuName}
                                                </Link>
                                            )
                                        })
                                }
                            </Fragment>
                        );
                    })
                }
            </Drawer>
        </Fragment>
    );
};

const mapStateToProps = (state: BaseState) => {
    return {
        isAuth: state.auth.isAuth,
        admin: state.user.data.admin,
        curator: state.user.data.curator,
    }
}

export default connect(mapStateToProps, null)(MainMenuMobile);
