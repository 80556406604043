import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import {Provider} from 'react-redux';
import createSagaMiddleware from "redux-saga"
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from "redux";
import {rootReducer} from "./Base/rootReducer";
import {composeWithDevTools} from 'redux-devtools-extension';
import {BrowserRouter} from 'react-router-dom';
import watchAll from "./Base/rootSaga";
import PopupAuthorization from "./Auth/components";
import {PopupSystem} from "./Popup/components";

const root = document.getElementById('root');



const saga = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(saga),
));

saga.run(watchAll);

if (root) {

    while (root.firstChild) root.removeChild(root.firstChild);

    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App/>
                <PopupSystem />
                <PopupAuthorization />
            </BrowserRouter>
        </Provider>,
        root
    );

}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
