import {TESTS_CONSTANTS} from "./constants";
import {IDataRunTest} from "./type";

export interface IStateTest {
    hash: string,
    isLoad: boolean,
    runTest: IDataRunTest,
    errorRunTest: boolean,
    isLoadSaveAnswer: boolean,
    myTesting: any[];
}


const initialState: IStateTest = {
    hash: '',
    isLoad: false,
    isLoadSaveAnswer: false,
    // @ts-ignore
    runTest: {},
    errorRunTest: false,
    myTesting: [],
}

export const testReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TESTS_CONSTANTS.NEW_TEST_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case TESTS_CONSTANTS.NEW_TEST_ANSWER:
            return {
                ...store,
                isLoad: false,
                hash: action.payload
            };
        case TESTS_CONSTANTS.CLEAR_TEST_HASH:
            return {
                ...store,
                hash: ''
            };
        case TESTS_CONSTANTS.GET_DATA_RUN_TEST_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case TESTS_CONSTANTS.GET_DATA_RUN_TEST_ANSWER:
            return {
                ...store,
                isLoad: false,
                runTest: action.payload
            };
        case TESTS_CONSTANTS.GET_DATA_RUN_TEST_ERROR:
            return {
                ...store,
                isLoad: false,
                errorRunTest: action.payload
            };
        case TESTS_CONSTANTS.SET_ANSWER_RUN_TEST:
            const {value, missed_questions, id_active_question} = action.payload;
            return {
                ...store,
                runTest: {
                    ...store.runTest,
                    value,
                    missed_questions,
                    id_active_question
                }
            };
        case TESTS_CONSTANTS.SAVE_ANSWER_RUN_TEST_REQUEST:
            return {
                ...store,
                isLoadSaveAnswer: true
            }
        case TESTS_CONSTANTS.SAVE_ANSWER_RUN_TEST_ANSWER:
            return {
                ...store,
                isLoadSaveAnswer: false
            }
        case TESTS_CONSTANTS.FINISH_TEST_REQUEST:
            return {
                ...store,
                isLoadSaveAnswer: true
            }
        case TESTS_CONSTANTS.FINISH_TEST_ANSWER:
            return {
                ...store,
                isLoadSaveAnswer: false
            }
        case TESTS_CONSTANTS.GET_LIST_TESTING_REQUEST:
            return {
                ...store,
                isLoad: true
            }
        case TESTS_CONSTANTS.GET_LIST_TESTING_ANSWER:
            return {
                ...store,
                isLoad: false,
                myTesting: action.payload
            }
        case TESTS_CONSTANTS.DELETE_TEST_REQUEST:
            return {
                ...store
            };
    }
    return store;
}
