import React from 'react';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(FolderSharedIcon);

const FolderSharedIconStyle = () => {
    return (
        <Style />
    );
};


export default FolderSharedIconStyle;