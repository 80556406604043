export enum PACKAGE_CONSTANTS {
    CREATE_REQUEST = 'PACKAGE_CONSTANTS/CREATE_REQUEST',
    START_REQUEST = 'PACKAGE_CONSTANTS/START_REQUEST',
    START_ANSWER = 'PACKAGE_CONSTANTS/START_ANSWER',
    START_ERROR = 'PACKAGE_CONSTANTS/START_ERROR',
    CLEAR_HASH = 'PACKAGE_CONSTANTS/CLEAR_HASH',

    GET_DATA_REQUEST = 'PACKAGE_CONSTANTS/GET_DATA_REQUEST',
    GET_DATA_ANSWER = 'PACKAGE_CONSTANTS/GET_DATA_ANSWER',
    GET_DATA_ERROR = 'PACKAGE_CONSTANTS/GET_DATA_ERROR',
    GET_UPDATE_DATA = 'PACKAGE_CONSTANTS/GET_UPDATE_DATA',

    CLEAR_ERROR = 'PACKAGE_CONSTANTS/CLEAR_ERROR',

    START_TEST = 'PACKAGE_CONSTANTS/START_TEST',
    FINISH_TEST = 'PACKAGE_CONSTANTS/FINISH_TEST',

    FINISH_PACKAGE = 'PACKAGE_CONSTANTS/FINISH_PACKAGE',

    FINISH_INTERPRETATION_PACKAGE = 'PACKAGE_CONSTANTS/FINISH_INTERPRETATION_PACKAGE',

    SEND_MESSAGE_MAIL_START_PACKAGE = 'PACKAGE_CONSTANTS/SEND_MESSAGE_MAIL_START_PACKAGE',
}