import React from "react";

import {Dialog} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";

const Style = withStyles({
    paper: {
        borderRadius: 25,
        maxWidth: 'none'
    }
})(Dialog);

const DialogStyle = ({children, ...props}) => {
    return (
        <Style {...props}>
            {children}
        </Style>
    );
};


export default DialogStyle;