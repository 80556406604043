//@ts-nocheck
import {call, put, select, takeLatest} from "redux-saga/effects";
import {PROFESSIONS} from "./constants";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {getDataProfessionsAnswer, getListProfessionsAnswer} from "./actions";

function* getListProfessionsSaga() {
    try {
        const request = yield call(getResource, '/getListProfessions/', METHOD_REQUEST.GET, {}, {});

        if (request.success) {
            yield put(getListProfessionsAnswer(request.success.data))
            // yield put(newTestAnswer(hash))

        }

        // if (request.error) {
        //     error = request.error.error_message;
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataProfessionsSaga({payload}: any) {
    try {
        const request = yield call(getResource, '/getDataProfessions/', METHOD_REQUEST.POST, {}, {data: payload});

        if (request.success) {
            yield put(getDataProfessionsAnswer(request.success.data))
            // yield put(newTestAnswer(hash))
        }

        // if (request.error) {
        //     error = request.error.error_message;
        //     console.warn(error);
        // }

    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

export default function* watchProfessions() {
    yield takeLatest(PROFESSIONS.GET_LIST_PROFESSIONS_REQUEST, getListProfessionsSaga);
    yield takeLatest(PROFESSIONS.GET_DATA_PROFESSIONS_REQUEST, getDataProfessionsSaga);
}