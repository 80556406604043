import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    },

})(SaveIcon);

const SaveIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default SaveIconStyle;