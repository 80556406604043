export type UserID = number;

export enum USER_GENDER {
    M = 'M',
    F = 'F'
}


export interface IUserData {
    id: number,
    login: string,
    gender: USER_GENDER,
    year: number,
    admin: number,
    phone: string,
    date_create: number,
    curator: number,
}
