import {IDataRunTest, TESTS, TESTS_FORM} from "./type";
import {TESTS_CONSTANTS} from "./constants";
import {USER_GENDER} from "../../User/store/types";

// Запрос на создание нового теста
export const newTestRequest = (data: {age: number, gender: USER_GENDER, forms: TESTS_FORM, time: number,  code: TESTS, user: number}) => {
    return {
        type: TESTS_CONSTANTS.NEW_TEST_REQUEST,
        payload: data
    }
};

// Ответ на создание нового теста. Возвращает hash
export const newTestAnswer = (hash: string) => {
    return {
        type: TESTS_CONSTANTS.NEW_TEST_ANSWER,
        payload: hash
    }
};

// Чистим hash теста и редакса, мы его передали в параметры адреса, теперь в редаксе он нам не нужен
export const clearHashTest = () => {
    return {
        type: TESTS_CONSTANTS.CLEAR_TEST_HASH,
    }
};


export const getDataRunTestRequest = (hash: string, code: TESTS, testId: number | null = 0, isCurator: boolean) => {
    return {
        type: TESTS_CONSTANTS.GET_DATA_RUN_TEST_REQUEST,
        payload: {hash, code, testId, isCurator}
    }
};

export const getDataRunTestAnswer = (data: IDataRunTest) => {
    return {
        type: TESTS_CONSTANTS.GET_DATA_RUN_TEST_ANSWER,
        payload: data
    }
};

export const getDataRunTestError = (error: boolean) => {
    return {
        type: TESTS_CONSTANTS.GET_DATA_RUN_TEST_ERROR,
        payload: error
    }
};

export const setAnswerRunTest = (data: {value: {q: number, a: string}[], missed_questions: number[], id_active_question: number}) => {
    return {
        type: TESTS_CONSTANTS.SET_ANSWER_RUN_TEST,
        payload: data
    }
};

export const saveAnswerRunTestRequest = (isCurator: boolean) => {
    return {
        type: TESTS_CONSTANTS.SAVE_ANSWER_RUN_TEST_REQUEST,
        payload: isCurator
    }
};

export const saveAnswerRunTestAnswer = () => {
    return {
        type: TESTS_CONSTANTS.SAVE_ANSWER_RUN_TEST_ANSWER,
    }
};

export const finishTestRequest = (isCurator: boolean) => {
    return {
        type: TESTS_CONSTANTS.FINISH_TEST_REQUEST,
        payload: isCurator
    }
};

export const finishTestAnswer = () => {
    return {
        type: TESTS_CONSTANTS.FINISH_TEST_ANSWER,
    }
};

export const getListTestingRequest = () => {
    return {
        type: TESTS_CONSTANTS.GET_LIST_TESTING_REQUEST,
    }
};

export const getListTestingAnswer = (data: any) => {
    return {
        type: TESTS_CONSTANTS.GET_LIST_TESTING_ANSWER,
        payload: data
    }
};

export const deleteTestRequest = (data: {id: number, type: string}) => {
    return {
        type: TESTS_CONSTANTS.DELETE_TEST_REQUEST,
        payload: data
    }
};