import React from 'react';
import BuildIcon from '@material-ui/icons/Build';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {}
})(BuildIcon);

const BuildIconStyle = ( {children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default BuildIconStyle;
