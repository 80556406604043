import React from 'react';
import SchoolIcon from '@material-ui/icons/School';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    },

})(SchoolIcon);

const SchoolIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default SchoolIconStyle;