export enum AUTH {
    AUTH_REQUEST = "AUTH/REQUEST",
    CLEAR_ERROR = "AUTH/CLEAR_ERROR",
    AUTH_ANSWER = "AUTH/ANSWER",
    CHECK_TOKEN = "AUTH/CHECK_TOKEN",
}

export enum REGISTRATION {
    REQUEST = "REGISTRATION/REQUEST",
    ERROR = "REGISTRATION/ERROR",
    CLEAR = "REGISTRATION/CLEAR",
}
export enum LOGOUT {
    REQUEST = "LOGOUT/REQUEST",
    ANSWER = "LOGOUT/ANSWER",
}

export enum CHANGE_PASSWORD {
    REQUEST = "CHANGE_PASSWORD/REQUEST",
    ANSWER = "CHANGE_PASSWORD/ANSWER",
    ERROR = "CHANGE_PASSWORD/ERROR",
    CLEAR = "CHANGE_PASSWORD/CLEAR",
    CHECK_HASH_REQUEST = "CHANGE_PASSWORD/CHECK_HASH_REQUEST",
    CHECK_HASH_ANSWER = "CHANGE_PASSWORD/CHECK_HASH_ANSWER",
    NEW_PASS_REQUEST = "CHANGE_PASSWORD/NEW_PASS_REQUEST",
    NEW_PASS_ANSWER = "CHANGE_PASSWORD/NEW_PASS_ANSWER",
    NEW_PASS_CLEAR = "CHANGE_PASSWORD/NEW_PASS_CLEAR",
}

