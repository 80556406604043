import React from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(MenuBookIcon);

const MenuBookIconStyle = ({children, ...props}) => {
    return (
      <Style />
    );
};


export default MenuBookIconStyle;