export enum POPUP_DATA_ERROR {
    DEFAULT  = 0,
    ERROR_EXIT = 130,
    CHANGE_SEND_PASSWORD = 1000,
    SAVE_NEW_PASSWORD = 1005,
    SAVE_NEW_PASSWORD_ACCOUNT = 1006,
    DOWNLOAD_TEST = 2000,
    ERROR_DOWNLOAD_TEST = 2404,

    ALERT_TEST_PAY = 3000,
}

export const popupDataError = new Map<POPUP_DATA_ERROR, { title: string, description: string }>([
    [POPUP_DATA_ERROR.DEFAULT,
        {
            title: 'Ошибка!',
            description: 'Ошибка соединения с сервером. Обновите страницу и повторите действие.'
        }
    ],
    [POPUP_DATA_ERROR.ERROR_EXIT,
        {
            title: 'Ошибка выхода!',
            description: 'Не удалось выйти из системы. Обновите страницу и повторите действие.'
        }
    ],
    [POPUP_DATA_ERROR.CHANGE_SEND_PASSWORD,
        {
            title: 'Запрос был успешно отправлен!',
            description: 'Запрос на восстановление пароля был отправлен на указанную вами почту. Следуйте инструкции в письме.'
        }
    ],
    [POPUP_DATA_ERROR.SAVE_NEW_PASSWORD,
        {
            title: 'Пароль был успешно изменен!',
            description: 'Ваш пароль был успешно изменен. Откройте окно авторизации и авторизируйтесь под новым паролем.'
        }
    ],
    [POPUP_DATA_ERROR.SAVE_NEW_PASSWORD_ACCOUNT,
        {
            title: 'Пароль был успешно изменен!',
            description: 'Ваш пароль был успешно изменен.'
        }
    ],
    [POPUP_DATA_ERROR.DOWNLOAD_TEST,
        {
            title: 'Фомирование файла!',
            description: 'Идет формирование файла с результатами тестирования. По окончанию формирования, данная вкладка закроется автоматически.'
        }
    ],
    [POPUP_DATA_ERROR.ERROR_DOWNLOAD_TEST,
        {
            title: 'Ошибка скачивания файла!',
            description: 'Не удалось получить данные для скачивание результата тестирования.'
        }
    ],
    [POPUP_DATA_ERROR.ALERT_TEST_PAY,
        {
            title: 'Оплата!',
            description: 'К сожалению, система оплаты находится на тестировании до 26 июня. Для заказа заключения напишите, пожалуйста,  нам на почту info@prof-assistant.ru.'
        }
    ],

])

// switch (error) {
//     case 130:
//
//         break;
//     case 135:
//         title = 'Ошибка сохранения!';
//         description = 'Не удалось сохранить данные. Обновите страницу и повторите действие.';
//         break;
//     case 140:
//         title = 'Ошибка скачивания PDF!';
//         description = 'Не удалось сохранить результат тестирования в PDF файл. Обновите страницу и повторите действие.';
//         break;
//     case 145:
//         title = 'Ошибка получения данных!';
//         description = 'Не удалось получить данные. Обновите страницу и повторите действие.';
//         break;
//     case 150:
//         title = 'Не удалось сохранить!';
//         description = 'Заполните все обязательные поля.';
//         break;
//     case 160:
//         title = 'Ошибка создания пакета!';
//         description = 'Не удалось создать пакет. Обновите страницу и повторите действие.';
//         break;
//     case 165:
//         title = 'Ошибка получения фильтров';
//         description = 'Не удалось получить данные по фильтрам. Обновите страницу.';
//         break;
//     case 170:
//         title = 'Ошибка скачивания файла!';
//         description = 'У вас нет доступа на скачивание данного результата тестирования.';
//         break;
//
//     case 1000:
//         title ='Запрос был успешно отправлен!';
//         description ='Запрос на восстановление пароля был отправлен на указанную вами почту. Следуйте инструкции в письме.';
//         break;
//     case 1005:
//         title ='Пароль был успешно изменен!';
//         description ='Ваш пароль был успешно изменен. Откройте окно авторизации и авторизируйтесь под новым паролем.';
//         break;
//
//     case 2000:
//         title ='Фомирование файла!';
//         description ='Идет формирование файла с результатами тестирования. По окончанию формирования, данная вкладка закроется автоматически.';
//         break;
//
//
//
//     case 6666:
//         title ='Оплата прошла!';
//         description ='По закрытию данного окна вас перенаправит на страницу вашего, купленного пакета.';
//         break;
// }
