import {TELEGRAM} from "./constants";


export interface ITelegramState {

}

const initialState: ITelegramState = {

}

export const telegramReducer = ( store = initialState, action: {type:string, payload?: any}) => {
    switch (action.type) {
        case TELEGRAM.SEND_MESSAGE:
           return store;
    }
    return store;
}
