import {ISetPopup, ISetPopupAuthorization} from "./types";
import {POPUP} from "./constants";
import mainRoutes from "../../Base/constants/routes";

const routes = mainRoutes();

export const setPopup = (data: ISetPopup)=>{
    return {
        type: POPUP.SET_POPUP,
        payload: data
    }
};

export const setPopupAuthorization = ({type, isOpen, url = routes.my_testing.url, name = {title: '', button: ''}}:ISetPopupAuthorization)=>{

    return {
        type: POPUP.SET_POPUP_AUTHORIZATION,
        payload: {
            type,
            isOpen,
            url,
            name
        }
    }
};
