import React from "react";
import style from "./index.module.scss";
import MainMenu from "../MainMenu";
import windowSize, {WindowSizeProps} from 'react-window-size';
import ButtonAuthorization from "../Autorization/ButtonAutorization";
import {BUTTON_AUTHORIZATION_TYPE} from "../Autorization/ButtonAutorization/type";
import MainMenuMobile from "../MainMenu/MainMenuMobile";
import { Link, useLocation } from 'react-router-dom';
import mainRoutes from "../../constants/routes";
import {POPUP_AUTHORIZATION_TYPE} from "../../../Popup/store/types";
import {connect, useSelector} from "react-redux";
import {BaseState} from "../../rootReducer";

interface IHeader extends WindowSizeProps {
}

const routes = mainRoutes();

const Header = (
    {
        windowWidth,
        // @ts-ignore
        authorization
    }: IHeader) => {

    const location = useLocation();

    const isNotVisibleMenu = (url: string) => {
        let visible = false;
        const mainUrl = routes.main.url
        const list = [routes.package.url]
        visible = mainUrl === url;

        list.forEach( it => {
           if (url.indexOf(it) !== -1) {
               visible = true;
           }
        });

        return false;

    }

    return (

        <div className={style.container}>
            <Link to={routes.main.url} className={windowWidth >= 600 ? style.logo : style.logo}
                  title={'На главную'}/>
            {isNotVisibleMenu(location.pathname)
                ? null
                : <div className={style.menu}>
                    <div className={style.menu_container}>
                        {
                            windowWidth > 950
                                ? <MainMenu/>
                                : <MainMenuMobile/>
                        }
                    </div>
                    {/*{*/}
                    {/*    !authorization && <div className={style.registration}>*/}
                    {/*        <ButtonAuthorization*/}
                    {/*            type={BUTTON_AUTHORIZATION_TYPE.TEXT}*/}
                    {/*            name={'Личный кабинет'}*/}
                    {/*            flag={POPUP_AUTHORIZATION_TYPE.AUTHORIZATION}*/}
                    {/*            style={''}*/}
                    {/*            url={window.location.href}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <ButtonAuthorization
                        type={BUTTON_AUTHORIZATION_TYPE.ICO}
                        name={'Личный кабинет'}
                        flag={POPUP_AUTHORIZATION_TYPE.AUTHORIZATION}
                        style={''}
                        url={window.location.href}
                    />

                </div>
            }


        </div>

    )
}

const mapStateToProps = (state: BaseState) => {
    return {
        authorization: state.auth.isAuth
    }
}

// @ts-ignore
export default connect(mapStateToProps, null)(windowSize(Header));
