//@ts-nocheck
import {call, put, select, takeLatest} from "redux-saga/effects";
import {BaseState} from "../../Base/rootReducer";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {
    createExamAnswer, getDataExamAnswer, getDataExamError,
    getDataTestedAnswer,
    getListExamsAnswer, getListExamsRequest,
    getListTestedAnswer,
    getListTestedRequest,
    saveDataTestedAnswer
} from "./actions";
import {CURATORS} from "./constants";
import {IDataExam, ITestedData} from "./type";
import {USER_GENDER} from "../../User/store/types";
import {downloadGetDataTestError} from "../../Download/store/actions";
function* getListTestedRequestSaga() {
    // @ts-ignore
    const token = yield select((state: BaseState) => state.auth.token);

    // @ts-ignore
    const request = yield call(getResource, '/getListTested/', METHOD_REQUEST.GET, {"Authorization": "Token " + token}, {});

    if (request.success) {
        yield put(getListTestedAnswer(request.success.data))
    }
}

function* getDataTestedRequestSaga({payload}: any) {
    // @ts-ignore
    const token = yield select((state: BaseState) => state.auth.token);

    // @ts-ignore
    const request = yield call(
        getResource,
        '/getTested/',
        METHOD_REQUEST.POST,
        {"Authorization": "Token " + token},
        {
            data: {
                id: payload
            }
        });

    if (request.success) {
        const requestData = request.success.data;
        const data: ITestedData = {
            id: Number(requestData.id),
            curator: Number(requestData.curator),
            date_create: Number(requestData.date_create),
            birthday: Number(requestData.birthday),
            group: Number(requestData.group),
            name: requestData.name,
            gender: requestData.gender === 'M' ? USER_GENDER.M : USER_GENDER.F
        }

        yield put(getDataTestedAnswer(data))
    }
}

function* saveDataTestedRequestSaga({payload}: any) {
    try {
        // @ts-ignore
        const token = yield select((state: BaseState) => state.auth.token);

        const url = payload.id === 0 ? '/createTested/' : '/editTested/'

        // @ts-ignore
        const request = yield call(
            getResource,
            url,
            METHOD_REQUEST.POST,
            {"Authorization": "Token " + token},
            {
                data: {
                    data: {
                        name: payload.fio,
                        gender: payload.gender,
                        birthday: payload.birthday,
                    },
                    id: payload.id
                }
            }
        );

        if (request.success.data) {
            yield put(saveDataTestedAnswer(Number(request.success.data)));
        }


    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* deleteTestedRequestSaga({payload}: any) {
    try {
        // @ts-ignore
        const token = yield select((state: BaseState) => state.auth.token);

        // @ts-ignore
        const request = yield call(
            getResource,
            '/deleteTested/',
            METHOD_REQUEST.POST,
            {"Authorization": "Token " + token},
            {
                data: {
                    id: payload
                }
            }
        );

        yield put(getListTestedRequest());


    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* createExamRequestSaga({payload}: any) {
    try {
        const token = yield select((state: BaseState) => state.auth.token);
        const request = yield call(
            getResource,
            '/createExam/',
            METHOD_REQUEST.POST,
            {"Authorization": "Token " + token},
            {data: payload}
        );

        if (request.success.data) {
            yield put(createExamAnswer(Number(request.success.data)));
        }


    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getListExamRequestSaga() {
    const token = yield select((state: BaseState) => state.auth.token);

    const request = yield call(getResource, '/getListExams/', METHOD_REQUEST.GET, {"Authorization": "Token " + token}, {});

    if (request.success) {
        yield put(getListExamsAnswer(request.success.data))
    }
}

function* deleteExamRequestSaga({payload}: any) {
    try {
        const token = yield select((state: BaseState) => state.auth.token);

        const request = yield call(
            getResource,
            '/deleteExam/',
            METHOD_REQUEST.POST,
            {"Authorization": "Token " + token},
            {
                data: {
                    id: payload
                }
            }
        );

        yield put(getListExamsRequest());


    } catch (error) {
        // yield put(setPopup(dataDefaultPopup))
        // yield put(authClearError())
    }
}

function* getDataExamRequestSaga({payload}: any) {
    const token = yield select((state: BaseState) => state.auth.token);

    const request = yield call(
        getResource,
        '/getDataExam/',
        METHOD_REQUEST.POST,
        {"Authorization": "Token " + token},
        {
            data: {
                id: payload
            }
        });

    if (request.success) {
        const requestData = request.success.data;
        const data: IDataExam = {
            id: Number(requestData.id),
            date_create: Number(requestData.date_create),
            name: requestData.name,
            post: requestData.post,
            packages: requestData.packages
        }

        yield put(getDataExamAnswer(data))
    }

    if (request.error) {
        yield put(getDataExamError(request.error.code))
    }
}


export default function* watchCurators() {
    yield takeLatest(CURATORS.GET_LIST_TESTED_REQUEST, getListTestedRequestSaga);
    yield takeLatest(CURATORS.SAVE_DATA_TESTED_REQUEST, saveDataTestedRequestSaga);
    yield takeLatest(CURATORS.GET_DATA_TESTED_REQUEST, getDataTestedRequestSaga);
    yield takeLatest(CURATORS.DELETE_TESTED_REQUEST, deleteTestedRequestSaga);
    yield takeLatest(CURATORS.CREATE_EXAM_REQUEST, createExamRequestSaga);
    yield takeLatest(CURATORS.GET_LIST_EXAMS_REQUEST, getListExamRequestSaga);
    yield takeLatest(CURATORS.DELETE_EXAM_REQUEST, deleteExamRequestSaga);
    yield takeLatest(CURATORS.GET_DATA_EXAM_REQUEST, getDataExamRequestSaga);
}