/**
 * Created by AL on 05.09.2018.
 */

import React, {useEffect} from 'react'
import {connect} from "react-redux";
import style from './index.module.scss'
import Button from '../../Button';
import {BUTTON_AUTHORIZATION_TYPE, IButtonType, IDefaultType, IIcoType} from './type';
import {setPopupAuthorization} from "../../../../Popup/store/actions";
import {ISetPopupAuthorization, POPUP_AUTHORIZATION_TYPE} from "../../../../Popup/store/types";
import {BaseState} from "../../../rootReducer";
import {logoutAnswer, logoutRequest} from "../../../../Auth/store/actions";
import {useHistory} from "react-router-dom";
import mainRoutes from "../../../constants/routes";


const ButtonType = ({width, name, flag, setPopupAuthorization, url, marathon, authorization}: IButtonType) => {

    const history = useHistory();

    return (
        <div style={{width: `${width}px`}}>
            <Button
                name={name}
                onClick={() => {
                    if (authorization) {
                        history.go(0);
                    } else {
                        const name = {title: '', button: ''}
                        if (marathon) {
                            name.title = 'Запись на марафон';
                            name.button = 'Записаться';
                        }
                        setPopupAuthorization({type: flag, isOpen: true, url, name});
                    }
                }}
            />
        </div>
    );
};

const IcoType = ({name, flag, setPopupAuthorization, url, authorization, logout, logoutRequest, logoutAnswer}: IIcoType) => {

    const history = useHistory();
    const routes = mainRoutes();

    useEffect(()=>{
        const url = history.location.pathname;

        const isPageAdmin =
            url.includes(routes.my_testing.url) ||
            url.includes(routes.admin.url) ||
            url.includes(routes.account.url) ||
            url.includes(routes.my_online_course.url);

        if (logout) {
            logoutAnswer(false);
            if (isPageAdmin) {
                history.push(routes.main.url);
            }
        }

        },
        [logout])

    if (authorization) {
        return (
            <div className={style.ico_exit}
                 title={"Выйти"}
                 onClick={() => {
                     logoutRequest()
                 }}
            />
        )
    } else {
        return (
            <div className={style.ico_authorization}
                 title={name}
                 onClick={() => {
                     setPopupAuthorization({type: flag, isOpen: true, url, name: {title: '', button: ''}})
                 }}
            />
        )
    }
}

const DefaultType = ({name, textStyle, flag, setPopupAuthorization, url, authorization}: IDefaultType) => {
    const history = useHistory();
    return (
        <div
            className={style[textStyle]}
            title={name}
            onClick={() => {
                if (authorization) {
                    history.push(url);
                } else {
                    setPopupAuthorization({type: flag, isOpen: true, url, name: {title: '', button: ''}});
                }
            }}
        >
            {name}
        </div>
    );
};

export interface IButtonAuthorization {
    type: BUTTON_AUTHORIZATION_TYPE,
    flag: POPUP_AUTHORIZATION_TYPE,
    name: string,
    style: string,
    width?: number,
    setPopupAuthorization?: (data: ISetPopupAuthorization) => void,
    url: string,
    marathon?: boolean,
    authorization?: boolean,
    logoutRequest?: () => void,
    logoutAnswer?: (flag: boolean) => void,
    logout?: boolean
}

const ButtonAuthorization = (
    {
        type,
        flag,
        name,
        style,
        width = 0,
        setPopupAuthorization = () => {
        },
        url,
        marathon = false,
        authorization = false,
        logoutRequest = () => {},
        logout = false,
        logoutAnswer = () => {}
    }: IButtonAuthorization) => {

    console.log()

    switch (type) {
        case BUTTON_AUTHORIZATION_TYPE.BUTTON:
            return <ButtonType width={width}
                               name={name}
                               flag={flag}
                               setPopupAuthorization={setPopupAuthorization}
                               url={window.location.pathname}
                               marathon={marathon}
                               authorization={authorization}
            />;
        case BUTTON_AUTHORIZATION_TYPE.ICO:
            return <IcoType name={name}
                            flag={flag}
                            setPopupAuthorization={setPopupAuthorization}
                            url={window.location.pathname}
                            logout={logout}
                            logoutRequest={logoutRequest}
                            logoutAnswer={logoutAnswer}
                            authorization={authorization}
            />;
        case BUTTON_AUTHORIZATION_TYPE.TEXT:
            return <DefaultType name={name}
                                textStyle={style}
                                flag={flag}
                                setPopupAuthorization={setPopupAuthorization}
                                authorization={authorization}
                                url={url}
            />;
        default :
            return <DefaultType name={name}
                                textStyle={style}
                                flag={flag}
                                setPopupAuthorization={setPopupAuthorization}
                                authorization={authorization}
                                url={window.location.pathname}
            />;
    }
};

const mapDispatchToProps = {
    setPopupAuthorization, logoutRequest, logoutAnswer
};

const mapStateToProps = (state: BaseState) => {
    return {
        authorization: state.auth.isAuth,
        logout: state.auth.logout
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAuthorization);

