import React from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root:{
        color: '#39b531',
        cursor: 'pointer'
    }
})(VisibilityOffIcon);

const VisibilityOffIconStyle = ({...props}) => {
    return (
        <Style {...props}/>
    );
};

export default VisibilityOffIconStyle;