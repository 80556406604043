//@ts-nocheck
import {call, put, select, takeLatest} from "redux-saga/effects";
import {PAY} from "./constants";
import {BaseState} from "../../Base/rootReducer";
import {getResource, METHOD_REQUEST} from "../../Base/helpers/get-requeat";
import {getPayDataListAnswer, getPayDataListError} from "./actions";

function* getPayDataListRequestSaga() {
    const token = yield select((state: BaseState) => state.auth.token);

    const request = yield call(getResource, '/getListFinance/', METHOD_REQUEST.GET, {"Authorization": "Token " + token}, {});

    if (request.success) {
        yield put(getPayDataListAnswer(request.success.data))
    }

    if (request.error) {
        yield put(getPayDataListError(request.error.code))
    }

}

function* sendPayYooKassaSaga({payload}: any) {
    const request = yield call(getResource, '/YooKassa/', METHOD_REQUEST.POST, {}, {data: payload});

    if (request.success && request.success.data) {
        //Инициализация виджета. Все параметры обязательные.
        // @ts-ignore
        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: request.success.data.confirmation.confirmation_token,
            return_url: request.success.data.metadata.url,
            customization: {
                modal: true,
                colors: {
                    control_primary: '#ff8210',
                    control_primary_content: '#ffffff',
                    background: '#ffffff',
                    border: '#ff8210'
                }
            },
            error_callback: function(e: any) {
                console.log(e)
            }
        });

        checkout.render('root');
    }

}

export default function* watchPay() {
    yield takeLatest(PAY.GET_LIST_REQUEST, getPayDataListRequestSaga);
    yield takeLatest(PAY.SEND_PAY_YOO_KASSA, sendPayYooKassaSaga);
}
