import React from 'react';
import {IconButton} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        '&:hover': {
            backgroundColor: '#e9ecf1'//'#ffd8b5'////'#ccffcc',
        },
    }
})(IconButton);

const IconButtonStyle = ({children, ...props}) => {
    return (
        <Style {...props}>
            {children}
        </Style>
    );
};


export default IconButtonStyle;