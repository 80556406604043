import React, {Suspense} from "react";
import { Route, Switch, useHistory, Redirect, useLocation } from 'react-router-dom';
import mainRoutes from "../../constants/routes";
import MainLoader from "../MainLoader";

const router = mainRoutes();

enum PARAMETER_URL {
    TEST = '/:hash?',
    PACKAGE = '/:hash?',
    RESULT_TEST = '/:id?',
    RESULT_PACKAGE = '/:hash?',
    PROFESSIONS_URL = '/:url?',
    CURATOR_TESTED = '/:edit?',
    CURATOR_EXAMS_SHOW = '/:show?',
    CURATOR_EXAMS_SHOW_INTERPRETATION = '/:admin?',
    CURATORS_TESTING = '/:hash?',
}

const Main = React.lazy(() =>
    import('../../../Pages/Main')
)

const Page404 = React.lazy(() =>
    import("../../../Pages/Page404")
)
const Sitemap = React.lazy(() =>
    import("../../../Pages/Sitemap")
)
const ContactOffer = React.lazy(() =>
    import("../../../Pages/ContactOffer")
)
const Politika = React.lazy(() =>
    import("../../../Pages/Politika")
)
const RecoveryPassword = React.lazy(() =>
    import("../../../Auth/components/RecoveryPassword")
)


const CategoryTest = React.lazy(() =>
    import("../../../Pages/CategoryTest")
)


const Test = React.lazy(() =>
    import("../../../Pages/Test")
)

const DownloadTest = React.lazy(() =>
    import("../../../Download/components/DownloadTest")
)

const DownloadPackage = React.lazy(() =>
    import("../../../Download/components/DownloadPackage")
)

const DownloadCuratorPackage = React.lazy(() =>
    import("../../../Download/components/DownloadCuratorPackage")
)

const Account = React.lazy(() =>
    import("../../../Pages/Account")
)

const MyTesting = React.lazy(() =>
    import("../../../Pages/MyTesting")
)

// const Admin = React.lazy(() =>
//     // import("../../../Pages/Admin")
// )

const CatalogueOfProfessions = React.lazy(() =>
    import("../../../Pages/CatalogueOfProfessions")
)


const Curators = React.lazy(() => import("../../../Pages/Curators/components/Curators"))
const Package = React.lazy(() => import("../../../Pages/Package"))
const Tested = React.lazy(() => import("../../../Pages/Curators/components/Tested"))
const Exams = React.lazy(() => import("../../../Pages/Curators/components/Exams"))
const Testing = React.lazy(() => import("../../../Pages/Curators/components/Testing"))
const CuratorNotificationTelegramPage = React.lazy(() => import("../../../Pages/Curators/components/CuratorNotificationTelegramPage/CuratorNotificationTelegramPage"));


const ElementProfessions = React.lazy(() =>
    import("../../../Pages/ElementProfessions")
)

const Routes = () => {

    const history = useHistory();

    const location = useLocation();

    // @ts-ignore
    return <Suspense fallback={<MainLoader/>}>
        <Switch>
            <Route exact path={router.main.url} children={() => <Main/>}/>
            <Route path={router["404"].url} children={() => <Page404/>}/>
            <Route path={router.sitemap.url} children={() => <Sitemap/>}/>
            <Route path={router.politika_konfidencialnosti.url} children={() => <Politika/>}/>
            <Route path={router.contract_offer.url} children={() => <ContactOffer/>}/>
            <Route path={router.change.url + '/:hash'} children={() => <RecoveryPassword/>}/>

            {/* Категории тестов */}
            <Route exact path={router.complex_personality_tests.url} children={() => <CategoryTest/>}/>
            <Route exact path={router.one_factor_personality_tests.url} children={() => <CategoryTest/>}/>
            <Route exact path={router.projective_tests.url} children={() => <CategoryTest/>}/>
            <Route exact path={router.the_iq_tests.url} children={() => <CategoryTest/>}/>
            <Route exact path={router.professional_quality_tests.url} children={() => <CategoryTest/>}/>
            <Route exact path={router.tests_on_the_type_of_interpersonal_relationships.url}
                   children={() => <CategoryTest/>}/>

            {/* Купленный пакет */}
            <Route exact path={router.package.url + PARAMETER_URL.PACKAGE} children={() => <Package/>}/>

            {/* Конкретный тест */}
            <Route path={router["complex_personality_tests/akcent"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["complex_personality_tests/kettel"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["complex_personality_tests/mmil"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["complex_personality_tests/keirsey"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>
            <Route path={router["one_factor_personality_tests/aizenk"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>
            {/*<Route path={router["one_factor_personality_tests/bdhi"].url + PARAMETER_URL.TEST}*/}
            {/*       children={() => <Test/>}/>*/}
            <Route path={router["one_factor_personality_tests/emotional_intelligence"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>
            <Route path={router["projective_tests/sondi"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["projective_tests/lyusher"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["the_iq_tests/kot"].url + PARAMETER_URL.TEST} children={() => <Test/>}/>
            <Route path={router["professional_quality_tests/map_of_interests"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>
            <Route path={router["professional_quality_tests/gerchikov"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>
            <Route path={router["tests_on_the_type_of_interpersonal_relationships/shutc"].url + PARAMETER_URL.TEST}
                   children={() => <Test/>}/>

            {/*Скачивание результата теста*/}
            <Route path={router.download_test.url + PARAMETER_URL.RESULT_TEST} children={() => <DownloadTest/>}/>

            {/*Скачивание результата тестирования (пакета)*/}
            <Route path={router.download_package.url + PARAMETER_URL.RESULT_PACKAGE}
                   children={() => <DownloadPackage/>}/>

            {/*Скачивание результата тестирования (пакета)*/}
            <Route path={router.download_curator_package.url + PARAMETER_URL.RESULT_PACKAGE}
                   children={() => <DownloadCuratorPackage/>}/>

            {/* Админка*/}
            <Route path={router.account.url} children={() => {
               // @ts-ignore
                return <Account/>;
            }}/>
            <Route path={router.my_testing.url} children={() => {
                // @ts-ignore
                return <MyTesting/>
            }}/>
            {/*<Route path={router.admin.url} children={() => <Admin/>}/>*/} //todo пока скрыл, нужно менять прокерку на
            куратора и админа

            {/* Каталог профессий */}
            <Route exact path={router.catalogue_of_professions.url}
                   children={() => <CatalogueOfProfessions/>}/>
            <Route path={router.catalogue_of_professions.url + PARAMETER_URL.PROFESSIONS_URL}
                   children={() => <ElementProfessions/>}/>

            {/* Раздел кураторы */}
            <Route exact path={router.curators.url} children={() => <Curators/>}/>
            <Route exact path={router.tested.url + PARAMETER_URL.CURATOR_TESTED} children={() => <Tested/>}/>
            <Route exact
                   path={router.exams.url + PARAMETER_URL.CURATOR_EXAMS_SHOW + PARAMETER_URL.CURATOR_EXAMS_SHOW_INTERPRETATION}
                   children={() => <Exams/>}/>
            <Route exact path={router.curators_testing.url + PARAMETER_URL.CURATORS_TESTING}
                   children={() => <Testing/>}/>
            <Route exact path={router.curators_telegram.url}
                   children={() => <CuratorNotificationTelegramPage/>}/>

            <Route exact path={router.self_realization.url}
                   children={() => <Redirect to={router.main.url}/>}/>
            <Route exact path={router.self_development.url}
                   children={() => <Redirect to={router.main.url}/>}/>
            <Route exact path={router.complex_testing.url}
                   children={() => <Redirect to={router.main.url}/>}/>
            <Route exact path={router['complex_testing/self_development_test'].url + PARAMETER_URL.PACKAGE}
                   children={() => {
                       const url = location.pathname.replace(router['complex_testing/self_development_test'].url, router.package.url);
                       return <Redirect to={url}/>
                   }}/>
            <Route exact path={router['complex_testing/self_realization_test'].url + PARAMETER_URL.PACKAGE}
                   children={() => {
                       const url = location.pathname.replace(router['complex_testing/self_realization_test'].url, router.package.url);
                       return <Redirect to={url}/>
                   }}/>


            {/* Перенаправление на 404, если на нашли адрес страницы */}
            <Route children={() => {
                history.push(router["404"].url);
                return null;
            }}/>


        </Switch>
    </Suspense>
}

export default Routes;
