import React from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(HomeOutlinedIcon);

const HomeOutlinedIconStyle = () => {
    return (
        <Style />
    );
};


export default HomeOutlinedIconStyle;