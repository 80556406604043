import {IDataProfessions, IElementProfessions} from "./type";
import {PROFESSIONS} from "./constants";

export interface IStateProfessions {
    list: IElementProfessions[],
    isLoad: boolean,
    data: IDataProfessions,
}


const initialState: IStateProfessions = {
    list: [],
    isLoad: false,
    // @ts-ignore
    data: {}
}

export const professionsReducer = (store = initialState, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case PROFESSIONS.GET_LIST_PROFESSIONS_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case PROFESSIONS.GET_LIST_PROFESSIONS_ANSWER:
            return {
                ...store,
                isLoad: false,
                list: action.payload
            };
        case PROFESSIONS.GET_DATA_PROFESSIONS_REQUEST:
            return {
                ...store,
                isLoad: true
            };
        case PROFESSIONS.GET_DATA_PROFESSIONS_ANSWER:
            return {
                ...store,
                isLoad: false,
                data: action.payload
            };
        case PROFESSIONS.CLEAR_DATA_PROFESSIONS:
            return {
                ...store,
                data: {}
            };
    }
    return store;
}
