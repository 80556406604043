import React from 'react';
import FlagSharpIcon from '@material-ui/icons/FlagSharp';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({

})(FlagSharpIcon);

const FlagSharpIconStyle = ( {children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default FlagSharpIconStyle;