import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#39b531',
    },

})(ChevronLeftIcon);

const ChevronLeftIconStyle = () => {
    return (
        <Style />
    );
};


export default ChevronLeftIconStyle;
