import React from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root: {
        color: '#dcdcdc',
    },

})(AssessmentIcon);

const AssessmentIconStyle = ({...props}) => {
    return (
        <Style {...props} />
    );
};


export default AssessmentIconStyle;