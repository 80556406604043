const host = window.location.host + '/api/v1/';

export const PayMasterMainUrl = 'https://info.paymaster.ru/';
export const yooKassaMainUrl = 'https://yookassa.ru/';


export const PAY = {
    URL: 'https://paymaster.ru/payment/init?',                   // url-адрес на который отправляем запрос GET или POST
    LMI_MERCHANT_ID: '829a7333-2ad0-4717-9984-cb94367d57ea',  // prof-assistant.ru
    // LMI_MERCHANT_ID: '4814afc5-3cd1-41a4-9f24-af6e01243b9c',  // alxav.tmweb.ru/
    LMI_CURRENCY: 'RUB', // Идентификатор валюты платежа.
    LMI_SIM_MODE: 0, // Идентификатор тестирования (0 - успешное выполнение, 1 - выполнение с ошибкой (платеж не выполнен), 2 - Около 80% запросов на платеж будут выполнены успешно, а 20% - не выполнены.)
    // LMI_INVOICE_CONFIRMATION_URL: host + 'InvoiceConfirmation/', // Url - Если присутствует, то запрос Invoice Confirmation будет отправляться по указанному URL
    // LMI_PAYMENT_NOTIFICATION_URL: host + 'PaymentNotification/',
    LMI_SUCCESS_URL: host + 'Success/',
    LMI_SUCCESS_METHOD: 'POST',
    LMI_FAIL_URL: host + 'Fail/',
    LMI_FAIL_METHOD: 'POST',
    LMI_PAYMENT_METHOD: 'BankCard'
}



export const PayBoxMainUtl  = 'https://api.paybox.money/init_payment.php';


export const PAYBOX = {
    URL: 'https://api.paybox.money/payment.php?',
    PG_MERCHANT_ID: 542367,
    PG_CURRENCY: 'RUB',
    PG_FAILURE_URL: host + 'Fail/',
    PG_FAILURE_URL_METHOD: 'POST',
    PG_LANGUAGE: 'ru',
    PG_SUCCESS_URL: host + 'Success/',
    PG_SUCCESS_URL_METHOD: 'POST',
    SECRET_KEY_ADD_PAY: 'rmWfhBd0pCdVNg8N',
    PG_RESULT_URL: host + 'resultPayBox/',
    PG_REQUEST_METHOD: 'POST',
}

//  LMI_PAYMENT_AMOUNT - Сумма платежа, которую продавец желает получить от покупателя. Сумма должна быть больше нуля, дробная часть отделяется точкой.
//  LMI_PAYMENT_NO - идентификатор покупки
//  LMI_PAYMENT_DESC - описание товара и услуги
//  LMI_PAYER_EMAIL - почта покупателя ( если есть)