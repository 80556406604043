import {IUserData, UserID} from "./types"
import { USER } from "./constants"

export const getDataUserRequest = () => {
    return {
        type: USER.GET_DATA_REQUEST,
    }
}

export const getDataUserAnswer = (data: IUserData) => {
    return {
        type: USER.GET_DATA_ANSWER,
        payload: data
    }
}

export const getDataUserError = (error: number) => {
    return {
        type: USER.GET_DATA_ERROR,
        payload: error
    }
}


export const updateDataUserRequest = (data: any) => {
    return {
        type: USER.UPDATE_DATA_REQUEST,
        payload: data
    }
}
