import React, {Fragment} from "react";
import style from './index.module.scss';

const Loader = ({children, ...props})=> {
    const {flag, size, alignItems = 'left', justifyContent = 'left', flexDirection = 'row'} = props;

    let ico;

    switch (size) {
        case 30: {
            ico = 'ico_30';
            break;
        }
        case 50: {
            ico = 'ico_50';
            break;
        }
        default:
            ico = 'ico';
    }

    const styleChildren = {
        opacity: 0,
        alignItems,
        justifyContent,
        flexDirection,
        flex: 1,
    };

    if (flag) {
        return (
            <Fragment>
                <div className={style["container"]}>
                    <div style={styleChildren}>{children}</div>
                    <div className={style["position"]}>
                        <div className={style[ico]}>

                        </div>
                    </div>

                </div>


            </Fragment>



        );
    }


    return (
        <Fragment>
            {children}
        </Fragment>
        )
};

export default Loader;
