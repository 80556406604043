import {IUserData, USER_GENDER} from "./types";

export enum USER {
    GET_DATA_REQUEST = "USER/GET_DATA_REQUEST",
    GET_DATA_ANSWER = "USER/GET_DATA_ANSWER",
    GET_DATA_ERROR = "USER/GET_DATA_ERROR",
    UPDATE_DATA_REQUEST = "USER/UPDATE_DATA_REQUEST",
}

export const defaultDataUser: IUserData = {
    id: 0,
    login: 'Пользователь не найден',
    gender: USER_GENDER.M,
    year: new Date().getFullYear(),
    admin: 0,
    phone: '',
    date_create: new Date().getTime(),
    curator: 0,
}


