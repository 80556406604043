import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';

const Style = withStyles({
    root:{}
})(PersonIcon);

const PersonIconStyle = ( {children, ...props}) => {
    return (
        <Style  {...props}>
            {children}
        </Style>
    );
};


export default PersonIconStyle;